<template>
  <mosaic-cohort-students-wrapper>
    <div v-if="!statusColumns.length" class="py-4">
      There are no {{ columnObjectTypePluralised }} assigned to this Cohort.
    </div>
    <mosaic-table
      v-else
      table-id="cc"
      :columns="monitoringColumns"
      :rows="rows"
      :object-type-pluralised="objectTypePluralised"
      show-not-assigned
      :export-config="exportConfig"
    ></mosaic-table>
  </mosaic-cohort-students-wrapper>
</template>

<script setup lang="ts">
import MosaicTable from '@/components/monitoring/MosaicTable.vue';
import type { Column, EnumColumn, Row } from '@/components/monitoring/mosaic-table';
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

type StudentRow = {
  id: number;
  name: string;
  studentGroups: { group_id: number }[];
  [key: number]: {
    value: string;
  };
};
const props = defineProps<{
  rows: StudentRow[];
  objectTypePluralised: string;
  columnObjectTypePluralised: string;
  statuses: {
    [key: string]: {
      text: string;
      color: string;
      sortOrder: number;
    };
  };
  statusColumns: { id: number; name: string }[];
  clickRoute?: (row: Row, actionId: number) => RouteLocationNamedRaw | undefined;
  exportConfig?: { title: string };
  nameClickRoute: (row: Row) => RouteLocationNamedRaw;
  selectedGroupId: number;
}>();

// Monitoring

const mappedStatusColumns = computed(() =>
  props.statusColumns.map<EnumColumn>(c => ({
    name: c.name,
    key: c.id.toString(),
    clickRoute: (row: Row) => props.clickRoute?.(row, c.id),
    chip: (row: Row) => {
      const studentRow = row as StudentRow;
      const data = studentRow[c.id];
      return {
        hide: false,
        text: props.statuses[data.value]?.text,
        color: props.statuses[data.value]?.color,
        value: data.value,
      };
    },
    filter: {
      type: 'select',
      items: [
        { title: 'All', value: null },
        ...(Object.keys(props.statuses) as string[]).map(x => ({ title: props.statuses[x].text, value: x })),
        { title: 'Not Assigned', value: 'not assigned' },
      ],
      value: (row: Row) => {
        const studentRow = row as StudentRow;
        const data = studentRow[c.id];
        return data.value ? data.value : 'not assigned';
      },
    },
    sort: (row: Row) => {
      const studentRow = row as StudentRow;
      const data = studentRow[c.id];
      return data.value ? props.statuses[data.value].sortOrder : 100;
    },
  }))
);

const monitoringColumns = computed<Column[]>(() => [
  {
    name: 'Name',
    key: 'name',
    sticky: true,
    clickRoute: (row: Row) => props.nameClickRoute(row),
    text: (row: Row) => row.name,
  },
  ...mappedStatusColumns.value,
]);
</script>
