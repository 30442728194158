<template>
  <div>
    <div>
      <v-tooltip location="bottom">
        <template #activator="{ props: downloadProps }">
          <mosaic-btn @click.prevent="downloadInstructors()" v-bind="downloadProps" :loading="exportProcessing">
            <mosaic-icon icon="mdi-table-arrow-down" color="black" />
          </mosaic-btn>
        </template>
        <span>Download Instructors</span>
      </v-tooltip>
      <mosaic-error-snackbar v-model="downloadError" action="download your Instructors" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';
import { ref } from 'vue';
import { useApi } from '@/composables/api';
import { useWebSockets } from '@/composables/web-sockets';
import { executeMosaicJob } from '@/utils/mosaic-job';

const { selectedInstitution, selectedCohort } = mapState();

export type Role = {
  id: number;
  name: string;
  pluralisedName: string;
  student_scope: boolean;
  permissions: {
    name: string;
  }[];
};

const downloadError = ref(false);

const api = useApi();
const ws = useWebSockets();
const exportProcessing = ref(false);
const { user } = mapState();

type PollResult = {
  download_url: string;
};

const downloadInstructors = () => {
  exportProcessing.value = true;
  const url = selectedCohort.value
    ? `/cohorts/${selectedCohort.value.id}/staff/export-async`
    : `/institutions/${selectedInstitution.value.id}/staff/export-async`;
  executeMosaicJob<PollResult>(
    api,
    ws,
    user.value.id,
    'export Instructors',
    url,
    {},
    ({ download_url }) => {
      window.open(download_url);
      exportProcessing.value = false;
    },
    () => {
      exportProcessing.value = false;
      downloadError.value = true;
    },
    {
      useWebSockets: true,
    }
  );
};
</script>
