import { useApi, type Api } from '@/composables/api';
import { useWebSockets } from '@/composables/web-sockets';
import type { WebSockets } from '@/entrypoints/application/web-sockets';
import { useUserStore } from '@/stores/user';
import { isAxiosError } from 'axios';
import { delay } from './async';
import config from './config';

export const mosaicUnfinishedJobStatuses = ['queued', 'started'] as const;
type MosaicUnfinishedJobStatusTuple = typeof mosaicUnfinishedJobStatuses;
export type MosaicUnfinishedJobStatus = MosaicUnfinishedJobStatusTuple[number];

export const mosaicFinishedJobStatuses = ['finished', 'errored'] as const;
type MosaicFinishedJobStatusTuple = typeof mosaicFinishedJobStatuses;
export type MosaicFinishedJobStatus = MosaicFinishedJobStatusTuple[number];

export type MosaicJobStatus = MosaicUnfinishedJobStatus | MosaicFinishedJobStatus | 'not_found';

export function mosaicJobFinished(status: MosaicJobStatus): boolean {
  return status === 'finished' || status === 'errored';
}

type UnfinishedResponse =
  | {
      status: 'errored';
      errorCode: string;
      errorMessage: string;
    }
  | {
      status: MosaicUnfinishedJobStatus;
    };

export function useMosaicJob() {
  const api = useApi();
  const ws = useWebSockets();
  const { user } = useUserStore();
  return <T>(
    action: string,
    url: string,
    body: Record<string, unknown>,
    onSuccess: (pollResult: T) => void,
    onError: (e: { errorCode: string; errorMessage: string; e?: Error }) => void,
    options: {
      useWebSockets?: boolean;
      maxAttempts?: number;
      waitInMs?: number;
    } = {}
  ) => executeMosaicJob<T>(api, ws, user.value.id, action, url, body, onSuccess, onError, options);
}

export async function executeMosaicJob<T>(
  api: Api,
  ws: WebSockets,
  userId: number,
  action: string,
  url: string,
  body: Record<string, unknown>,
  onSuccess: (pollResult: T) => void,
  onError: (e: { errorCode: string; errorMessage: string; e?: Error }) => void,
  options: {
    useWebSockets?: boolean;
    maxAttempts?: number;
    waitInMs?: number;
  } = {}
) {
  type Response = (T & { status: 'finished' }) | UnfinishedResponse;
  let data: Response | undefined = undefined;
  let useWebSockets = options.useWebSockets && config.supportsWebSockets;
  try {
    // Could add a timeout to the connection and if it's not quicker than say a second then revert to polling?
    // To avoid race conditions we need to guarantee connection before starting the job, but that slows us down in a poor connectivity situation
    // If connection state management is done elsewhere they we can just see if we're currently connected
    if (useWebSockets) {
      try {
        const connected = await ws.connect(userId);
        if (!connected) useWebSockets = false;
      } catch (e) {
        console.log(`Can't connect to Ably`, e);
        useWebSockets = false;
      }
    }

    // poll_url_v2 is used before poll_url. This was for migrating student reviews and we can move it back to just using poll_url
    // when frontends have been migrated
    const r = await api.post<unknown, { poll_url: string; ably_event_name: string; poll_url_v2: string }>(url, body);

    if (useWebSockets) {
      const result = await Promise.race([
        new Promise<Response>(resolve =>
          ws.subscribeForOneMessage(userId, r.data.ably_event_name, async () => {
            const result = await api.get<Response>(r.data.poll_url_v2 || r.data.poll_url);
            resolve(result.data);
          })
        ),
        delay(30000),
      ]);

      // If there is no result (because the delay won the race) then revert to polling
      if (result) {
        data = result;
      }
    }

    if (!data) {
      const pollResult = await api.pollGet<Response>(r.data.poll_url_v2 || r.data.poll_url, {
        retryOnSuccess: r => !mosaicJobFinished(r.data.status),
        maxAttempts: options.maxAttempts,
        waitInMs: options.waitInMs,
      });
      data = pollResult.data;
    }
  } catch (e: unknown) {
    if (useWebSockets) {
      ws.disconnect();
    }

    if (!isAxiosError(e)) {
      console.log(e);
    } else if (e.response?.status == 422 && e.response?.data.error_code) {
      onError({ errorCode: e.response?.data.error_code, errorMessage: e.response?.data.message });
      return;
    }
    onError({ errorCode: 'network_error', errorMessage: `Sorry, cannot ${action} at the moment`, e: e as Error });
    return;
  }

  if (data.status == 'errored') {
    onError(data);
  }

  if (data.status == 'finished') {
    onSuccess(data);
  }
}
