<template>
  <div>
    <mosaic-loading-card v-if="stateProcessing" />
    <mosaic-load-error-card v-else-if="stateError || error" object-type="Cohort" @retry="loadCohort" />

    <mosaic-card v-else>
      <mosaic-card-title>Cohort Settings</mosaic-card-title>
      <mosaic-error-alert :error="saveError" action="save this Cohort's details" />
      <mosaic-text-field
        v-model="name"
        class="pt-4"
        autofocus
        name="cohort-name"
        label="Name"
        prepend-icon="mdi-google-classroom"
        half-width
      />
      <div v-if="!selectedInstitution.config.mentor_training_only">
        <mosaic-card-heading class="pt-4">Monitoring Windows</mosaic-card-heading>
        <div class="pt-2">
          Monitoring Windows determine the start and end dates for week-level monitoring within Mosaic, e.g. weekly
          Target counts
        </div>
        <mosaic-data-creation-list
          v-model="monitoringWindows"
          :new-object="newWindow"
          object-type="Monitoring Window"
          :can-add-new-object="canAddMonitoringWindow"
          show-clear-all
          @update:add-object="addMonitoringWindow()"
        >
          <template #item="{ item: window }"
            ><mosaic-text-field
              v-model="window.name"
              name="window-name"
              label="Name"
              class="pr-4 flex-grow-1"
              prepend-icon="mdi-monitor" />
            <mosaic-date-picker v-model:date="window.startDate" label="Start date" class="pr-2"></mosaic-date-picker>
            <mosaic-date-picker v-model:date="window.endDate" label="End date"></mosaic-date-picker>
            <mosaic-error-icon
              v-if="window.startDate && window.endDate && !validDates(window)"
              tooltip="End date must be
          after start date" />
            <mosaic-warning-icon
              v-else-if="overlapping(window)"
              tooltip="This Monitoring Window overlaps with another"
            ></mosaic-warning-icon
          ></template>
          <template #new-item>
            <mosaic-text-field
              v-model="newWindow.name"
              name="new-window-name"
              label="Name"
              class="flex-grow-1 pr-4"
              prepend-icon="mdi-monitor-star" />

            <mosaic-date-picker v-model:date="newWindow.startDate" label="Start date" class="pr-2"></mosaic-date-picker>

            <mosaic-date-picker v-model:date="newWindow.endDate" label="End date" />

            <mosaic-error-icon
              v-if="newWindow.startDate && newWindow.endDate && !validDates(newWindow)"
              tooltip="End date must be
          after start date" />
            <mosaic-warning-icon
              v-else-if="overlapping(newWindow)"
              tooltip="This Monitoring Window overlaps with another"
          /></template>
        </mosaic-data-creation-list>
        <mosaic-checkbox
          v-model="monitoringWeeksStartOnMondays"
          class="pt-4"
          prepend-icon="mdi-table-multiple"
          label="Use Monday as start of week for weekly monitoring views"
        />
        <div v-if="userStaffHasPermission('folderTemplates.edit')">
          <mosaic-card-heading class="pt-6">Folder Structure Template</mosaic-card-heading>
          <div class="pt-2">
            The Folder Structure Template determines the folder structure that will be created for each
            {{ traineeNounCapitalised() }} in this Cohort. The folder structure is created in the
            {{ traineeNounCapitalised() }}'s {{ selectedInstitution.storage_type }}, and is used to store all of the
            {{ traineeNounCapitalised() }}'s work for the year.
          </div>
          <mosaic-select
            v-model="selectedFolderStructureTemplateId"
            name="select-folder-structure-template"
            label="Folder Structure Template"
            :items="folderStructureItems"
            item-value="id"
            item-title="name"
            no-data-text="No folder structure templates available"
            prepend-icon="mdi-file-tree"
            :disabled="loadingTemplates"
          />
          <mosaic-info-alert v-if="selectedFolderStructureTemplateId !== selectedCohort.folder_structure_template_id"
            ><template v-if="selectedFolderStructureTemplateId !== null"
              >Please note: changing the Folder Structure Template for this Cohort will have no impact on
              {{ traineeNounCapitalisedAndPluralised }} who have already set up their Files area. The new Template will
              only be used to create folders in the Files area of any {{ traineeNounCapitalisedAndPluralised }} visiting
              for the first time.</template
            ><template v-else
              >Please note: removing the Folder Structure Template for this Cohort will have no impact on
              {{ traineeNounCapitalisedAndPluralised }} who have already set up their Files area. With no template
              selected, any {{ traineeNounCapitalisedAndPluralised }} visiting their Files area for the first time will
              just see an empty area.</template
            >
          </mosaic-info-alert>
        </div>
        <mosaic-card-heading class="pt-6">Configuration Options</mosaic-card-heading>
        <div class="d-flex align-center">
          <mosaic-checkbox
            name="show-student-files"
            class="mr-2"
            prepend-icon="mdi-file-document"
            :label="`Show ${traineeNounCapitalised()} files`"
            v-model="showStudentFiles"
          />

          <mosaic-help
            >Determines whether the Files area will display for {{ traineeNounCapitalisedAndPluralised }} in this
            Cohort</mosaic-help
          >
        </div>
      </div>
      <div class="d-flex flex-wrap justify-end align-center mt-2" style="row-gap: 4px">
        <mosaic-btn class="mr-2" @click.prevent="cancel()">Cancel</mosaic-btn>
        <mosaic-btn :disabled="!canSave" :loading="saveProcessing" color="primary" @click.prevent="save()"
          >Save</mosaic-btn
        >
      </div>
      <mosaic-success-snackbar v-model="saveSuccess" object-type="Cohort" />
    </mosaic-card>
    <unsaved-changes-dialog v-model:unsaved-changes-dialog="unsavedChangesDialog" object-type="Cohort" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { mapStateProcessingAndError } from '@/store/map-store';
import unsavedChangesMixin from '../../components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import moment from 'moment';
export default {
  name: 'TutorAdminCohortEditPage',
  components: { UnsavedChangesDialog },
  mixins: [unsavedChangesMixin],
  ...unsavedChangesMixin,
  data() {
    return {
      name: '',
      error: false,
      loadingTemplates: false,
      saveError: false,
      monitoringWindows: [],
      showStudentFiles: false,
      folderStructureTemplates: [],
      selectedFolderStructureTemplateId: null,
      selectedFolderStructureTemplate: null,
      saveProcessing: false,
      saveSuccess: false,
      monitoringWeeksStartOnMondays: false,
      newWindow: {
        name: '',
        startDate: null,
        endDate: null,
      },
    };
  },
  watch: {
    dirtyComputed(x) {
      this.dirty = x;
    },
  },
  computed: {
    ...mapState(['selectedCohort', 'selectedInstitution']),
    ...mapStateProcessingAndError(['loadCohort']),
    breadcrumbs() {
      return [
        {
          text: 'Cohort Settings',
        },
      ];
    },
    canAddMonitoringWindow() {
      return !!(
        this.newWindow.name &&
        this.newWindow.startDate &&
        this.newWindow.endDate &&
        this.validDates(this.newWindow)
      );
    },
    dirtyComputed() {
      return (
        this.name !== this.selectedCohort.name ||
        this.showStudentFiles !== this.selectedCohort.show_student_files ||
        this.monitoringWeeksStartOnMondays !== this.selectedCohort.monitoring_weeks_start_on_mondays ||
        this.monitoringWindows.length !== this.selectedCohort.placements.length ||
        this.monitoringWindows.some(w => this.windowDirty(w)) ||
        (this.userStaffHasPermission('folderTemplates.edit') &&
          this.selectedFolderStructureTemplateId !== this.selectedCohort.folder_structure_template_id)
      );
    },
    canSave() {
      return (
        this.dirty &&
        !this.stateProcessing &&
        !this.saveProcessing &&
        this.name &&
        this.monitoringWindows.length > 0 &&
        this.monitoringWindows.every(w => this.validDates(w))
      );
    },
    folderStructureItems() {
      return this.folderStructureTemplates
        .map(x => {
          return {
            id: x.id,
            name: x.name,
          };
        })
        .concat([
          {
            id: null,
            name: 'None',
          },
        ]);
    },
  },
  async created() {
    this.name = this.selectedCohort.name;
    this.showStudentFiles = this.selectedCohort.show_student_files;
    this.monitoringWeeksStartOnMondays = this.selectedCohort.monitoring_weeks_start_on_mondays;
    this.setMonitoringWindows();
    await this.loadTemplates();
  },
  methods: {
    validDates(window) {
      return window.startDate && window.endDate && moment(window.startDate).isBefore(window.endDate);
    },
    overlapping(window) {
      return this.monitoringWindows.some(
        w =>
          moment(w.startDate).isBetween(window.startDate, window.endDate) ||
          moment(w.endDate).isBetween(window.startDate, window.endDate) ||
          moment(window.startDate).isBetween(w.startDate, w.endDate) ||
          moment(window.endDate).isBetween(w.startDate, w.endDate)
      );
    },
    async loadCohort(force = false, background = false) {
      await this.$store.dispatch('loadCohort', {
        cohortId: this.$route.params.cohortId,
        force: force,
        background: background,
      });
    },
    async loadTemplates() {
      if (this.userStaffHasPermission('folderTemplates.edit')) {
        try {
          this.loadingTemplates = true;
          const response = await this.$api.get(
            `/institutions/${this.selectedInstitution.id}/folder-structure-templates`
          );
          this.folderStructureTemplates = response.data;
          this.selectedFolderStructureTemplateId =
            this.folderStructureTemplates.find(t => t.id === this.selectedCohort.folder_structure_template_id)?.id ||
            null;
        } catch (e) {
          this.error = true;
          console.log(e);
        }
        this.loadingTemplates = false;
      }
    },
    setMonitoringWindows() {
      this.monitoringWindows = this.selectedCohort.placements.map(p => {
        return {
          name: p.name,
          startDate: p.start_date,
          endDate: p.end_date,
          id: p.id,
        };
      });
    },
    addMonitoringWindow() {
      this.monitoringWindows.push({ ...this.newWindow });
      this.newWindow = {
        name: '',
        startDate: null,
        endDate: null,
      };
    },
    windowDirty(window) {
      if (!window.id) {
        return true;
      }
      const original = this.selectedCohort.placements.find(p => p.id === window.id);
      return (
        window.name !== original.name ||
        window.startDate !== original.start_date ||
        window.endDate !== original.end_date
      );
    },
    async save() {
      this.saveProcessing = true;
      this.saveError = false;
      try {
        await this.$api.put(`/cohorts/${this.selectedCohort.id}`, {
          name: this.name,
          placements: this.monitoringWindows.map(w => {
            return {
              id: w.id,
              name: w.name,
              start_date: w.startDate,
              end_date: w.endDate,
            };
          }),
          folder_structure_template_id: this.selectedFolderStructureTemplateId,
          show_student_files: this.showStudentFiles,
          monitoring_weeks_start_on_mondays: this.monitoringWeeksStartOnMondays,
        });
        this.saveSuccess = true;
        await this.loadCohort(true, true);
        this.setMonitoringWindows();
      } catch (e) {
        this.saveError = true;
        console.log(e);
      }
      this.saveProcessing = false;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
