<!--
TableId prop
- There are pages with more that one table and with columns with the same name (e.g. week 1)
- It could also clash with other params not related to the table
- To sync the filters to the query params, it needs an id (props.tableId)
- This id cannot be random so that user can come back to the page wtth the same filters
-->

<template>
  <div>
    <div class="filters-export-container mb-2">
      <div class="data-filters">
        <template v-for="sf in selectFilters" :key="sf.label">
          <mosaic-select
            v-if="sf.items && sf.items.length > 0"
            :name="sf.name"
            no-icon
            v-model="dataFiltersInUse[sf.key]"
            item-value="value"
            item-title="name"
            :multiple="sf.multiple"
            class="multiple-v-select mb-3 mr-3"
            :label="sf.name"
            :items="sf.items"
            hide-details
          >
            <template v-if="sf.itemDetails" #item="{ item, props: slotProps }">
              <v-list-item v-bind="slotProps" :key="item.value">
                <template #title>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-icon
                        class="mx-2"
                        :color="dataFiltersInUse[sf.key].includes(item.value) ? 'primary' : 'secondary'"
                        >{{
                          dataFiltersInUse[sf.key].includes(item.value)
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank-outline'
                        }}</v-icon
                      >

                      <span class="mr-2">{{ item.title }}</span>
                    </div>
                    <mosaic-tooltip-chip v-if="item.raw.chipIcon && item.raw.chipTooltip" color="primary"
                      ><template #text>
                        <v-icon>{{ item.raw.chipIcon }}</v-icon>
                      </template>
                      <template #tooltip>{{ item.raw.chipTooltip }}</template></mosaic-tooltip-chip
                    >
                  </div>
                </template>
              </v-list-item>
            </template>
          </mosaic-select>
        </template>
      </div>
    </div>
    <div class="mb-3" v-if="hasFilters || exportConfig || (graphLabels && graphLabels.length > 0)">
      <v-divider />
      <div class="parent">
        <div v-if="graphLabels && graphLabels.length > 0" class="graph-labels">
          <span class="my-2 font-weight-medium" v-for="label in graphLabels" :key="label.value">
            <v-icon :color="label.color">mdi-square-rounded</v-icon>
            {{ label.name }}
          </span>
        </div>
        <div class="table-buttons d-flex justify-end">
          <div class="clickable pr-2 py-2 ml-2" v-if="hasFilters" @click="resetFilters()">
            <v-icon class="mr-2" color="primary">mdi-reload</v-icon>
            <span>Clear Filters</span>
          </div>
          <div class="clickable pr-2 py-2 ml-2" @click="exportToExcel" v-if="exportConfig">
            <v-icon class="mr-2 ml-4" color="primary">mdi-table-arrow-down</v-icon>
            <span>Export Table</span>
          </div>
        </div>
      </div>
      <v-divider />
    </div>
    <div class="d-flex flex-column-reverse">
      <mosaic-pagination
        v-model="currentPage"
        v-model:page-size="pageSize"
        class="mt-2"
        :total="paginationTotal"
        :include-top-margin="false"
        :mosaic-key="tableId"
      />
      <v-table :fixed-header="fixedHeader">
        <thead>
          <tr>
            <th
              v-for="c in internalColumns"
              :key="c.key"
              class="px-2 pb-2"
              :class="{
                'sticky-column': c.sticky,
              }"
              :style="{
                'min-width': !c.width ? props.minWidth : 'default',
                width: c.width || (paginatedRows.length === 0 ? props.minWidth : 'default') || 'default',
              }"
            >
              <div
                class="d-flex align-start"
                :style="{ cursor: !c.sort ? 'default' : 'pointer !important' }"
                v-on="c.sort ? { click: () => sortByColumn(c) } : {}"
              >
                <div class="flex-grow-1 d-flex">
                  <span @mouseover="onColumnHover($event, c.key)">
                    <slot v-if="slots['header-cell']" name="header-cell" v-bind="{ c, props }"></slot>
                    <v-tooltip v-else-if="tooltipColumnKeys.includes(c.key)" location="top">
                      <template #activator="{ props: tooltipProps }">
                        <span class="column-header-text" v-bind="tooltipProps">{{ c.name }}</span>
                      </template>
                      <span>{{ c.name }}</span>
                    </v-tooltip>
                    <span v-else class="column-header-text">{{ c.name }}</span>
                  </span>
                  <mosaic-help icon-style="margin: 3px 0 0 5px;" size="compact" v-if="c.helpText">{{
                    c.helpText
                  }}</mosaic-help>
                  <mosaic-help icon-style="margin: 3px 0 0 5px;" size="compact" v-if="c.helpTextHtml"
                    ><div v-html="c.helpTextHtml"></div
                  ></mosaic-help>
                </div>
                <div v-if="c.sort">
                  <v-icon v-if="sortBy !== c.key">mdi-swap-vertical</v-icon>
                  <v-icon v-else-if="sortDesc">mdi-arrow-down</v-icon>
                  <v-icon v-else>mdi-arrow-up</v-icon>
                </div>
              </div>
            </th>
          </tr>
          <tr v-if="hasFilters">
            <th
              v-for="c in internalColumns"
              :key="c.key"
              class="px-2"
              :class="{
                'sticky-column': c.sticky,
              }"
            >
              <div v-if="c.filter" class="d-flex align-center">
                <v-text-field
                  v-if="isTextColumn(c)"
                  hide-details
                  density="compact"
                  :model-value="getFilterValue(c.key, filterValues)"
                  @update:model-value="filterUpdated(c.key, $event)"
                />
                <div v-if="isEnumColumn(c)">
                  <mosaic-role-select
                    v-if="c.isRoles"
                    :multiple="c.filter?.multiple"
                    name="c.key"
                    :items="c.filter.items"
                    item-value="value"
                    class="mb-2"
                    item-title="title"
                    :model-value="getFilterValue(c.key, filterValues)"
                    @update:model-value="filterUpdated(c.key, $event as string)"
                    hide-details
                    :min-width="c.filter?.minWidth"
                    no-margin
                  />
                  <mosaic-select
                    v-else
                    no-label
                    :multiple="c.filter?.multiple"
                    name="c.key"
                    :items="c.filter.items"
                    item-value="value"
                    class="mb-2"
                    item-title="title"
                    :model-value="getFilterValue(c.key, filterValues)"
                    @update:model-value="filterUpdated(c.key, $event as string)"
                    hide-details
                    :min-width="c.filter?.minWidth"
                    no-margin
                  />
                </div>
                <div v-if="isNumberColumn(c)" class="d-flex align-center">
                  <mosaic-text-field
                    :name="`${c.key}-min`"
                    hide-details
                    density="compact"
                    type="number"
                    :model-value="getMinRangeFilterValue(c.key)"
                    style="width: 30px"
                    no-icon
                    @update:model-value="minRangeFilterUpdated(c.key, $event)"
                  />
                  <span class="px-1">to</span>
                  <mosaic-text-field
                    :name="`${c.key}-max`"
                    hide-details
                    density="compact"
                    type="number"
                    :model-value="getMaxRangeFilterValue(c.key)"
                    style="width: 30px"
                    no-icon
                    @update:model-value="maxRangeFilterUpdated(c.key, $event)"
                  />
                </div>
                <div class="mt-2">
                  <mosaic-icon class="ml-1" icon="filter" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedRows.length === 0">
            <td colspan="100" class="text-start">
              There are no {{ props.objectTypePluralised }}
              <span v-if="rows.length === 0 && props.noDataSuffix"> {{ props.noDataSuffix }}</span
              ><span v-else>for these filters</span>
            </td>
          </tr>
          <tr v-for="row in paginatedRows" :key="row.id">
            <td
              v-for="c in props.columns"
              :key="`${row.id}-${c.key}`"
              class="px-2"
              :class="{
                'highlight-cell-on-hover': c.clickRoute && c.clickRoute(row),
                'sticky-column': c.sticky,
                'disable-row': row.disabled,
              }"
              :style="{
                cursor: c.clickRoute && c.clickRoute(row) ? 'pointer' : 'default',
              }"
              v-on="
                c.clickRoute && c.clickRoute(row)
                  ? {
                      click: () => c.clickRoute && $router.push(c.clickRoute(row) || {}),
                    }
                  : {}
              "
            >
              <div v-if="areFiltersApplicableForRow(row, c)">
                <div v-if="isTextColumn(c)">
                  <div
                    :class="
                      c.textColor && c.textColor(row) && !c.textColor(row).startsWith('#')
                        ? { ['text-' + c.textColor(row)]: true }
                        : {}
                    "
                    :style="{
                      color:
                        c.textColor && c.textColor(row) && c.textColor(row).startsWith('#')
                          ? c.textColor(row)
                          : 'default',
                      'white-space': noOverflow ? 'default' : 'nowrap',
                    }"
                    class="d-flex"
                  >
                    <div class="mr-1" v-if="c.prependHelpText && c.prependHelpText(row)">
                      <mosaic-help icon-style="margin: 3px 0 0 5px;" size="compact">{{
                        c.prependHelpText(row)
                      }}</mosaic-help>
                    </div>
                    <span>{{ c.text(row) }}</span>
                  </div>

                  <div v-if="c.secondaryText" style="font-size: 0.75rem">{{ c.secondaryText(row) }}</div>
                </div>
                <div v-else-if="isEnumColumn(c) && c.multipleChips">
                  <template v-if="c.multipleChips(row)">
                    <div>
                      <div v-if="c.isRoles" class="d-flex">
                        <div v-for="value in c.multipleChips(row)" :key="value.value[0]">
                          <mosaic-role-completed-chip
                            class="pr-1"
                            :role-id="value.value"
                            :completed="false"
                            :role-scoped-tooltip="(role:RoleWithStudent ) => role.name"
                          />
                        </div>
                      </div>
                      <div v-else v-for="value in c.multipleChips(row)" :key="value.value[0]">
                        <v-tooltip v-if="value.tooltip" location="top">
                          <template #activator="{ props: tooltipProps }">
                            <v-chip :color="value.color" v-bind="tooltipProps">
                              <v-icon v-if="value.icon" :color="value.iconColor">{{ value.icon }}</v-icon>
                              <span v-if="value.text">{{ value.text }}</span>
                            </v-chip>
                          </template>
                          <span>{{ value.tooltip }}</span>
                        </v-tooltip>
                        <v-chip :color="value.color">
                          <v-icon v-if="value.icon">{{ value.icon }}</v-icon>
                          <span v-if="value.text">{{ value.text }}</span>
                        </v-chip>
                      </div>
                    </div>
                  </template>
                  <div v-else-if="showNotAssigned" class="not-assigned-text">Not Assigned</div>
                </div>
                <div v-else-if="isEnumColumn(c) && c.chip && !c.chip(row).hide">
                  <template v-if="c.chip(row).value">
                    <v-tooltip v-if="c.chip(row).tooltip" location="top">
                      <template #activator="{ props: tooltipProps }">
                        <v-chip :color="c.chip(row).color" v-bind="tooltipProps">
                          <v-icon v-if="c.chip(row).icon" :color="c.chip(row).iconColor">{{ c.chip(row).icon }}</v-icon>
                          <span v-if="c.chip(row).text">{{ c.chip(row).text }}</span>
                        </v-chip>
                      </template>
                      <span>{{ c.chip(row).tooltip }}</span>
                    </v-tooltip>
                    <v-chip v-else :color="c.chip(row).color">
                      <v-icon v-if="c.chip(row).icon">{{ c.chip(row).icon }}</v-icon>
                      <span v-if="c.chip(row).text">{{ c.chip(row).text }}</span>
                    </v-chip>
                  </template>
                  <div v-else-if="showNotAssigned" class="not-assigned-text">Not Assigned</div>
                </div>
                <div v-if="isNumberColumn(c)">
                  <div
                    :class="
                      c.valueColor && c.valueColor(row) && !c.valueColor(row).startsWith('#')
                        ? { ['text-' + c.valueColor(row)]: true }
                        : {}
                    "
                    :style="
                      c.valueColor && c.valueColor(row) && c.valueColor(row).startsWith('#')
                        ? { color: c.valueColor(row) }
                        : {}
                    "
                    class="d-flex align-center"
                    style="white-space: nowrap"
                  >
                    <span>{{
                      c.friendlyAlternativeValue
                        ? c.friendlyAlternativeValue(row, dataFiltersInUse)
                        : c.value(row, dataFiltersInUse)
                    }}</span>
                  </div>
                </div>
                <div v-if="isGraphColumn(c)">
                  <div
                    class="d-flex graph-container"
                    v-if="c.items(row, dataFiltersInUse).some(item => item.width > 0)"
                  >
                    <mosaic-horizontal-bar
                      v-for="item in c.items(row, dataFiltersInUse)"
                      :key="item.key"
                      :width="`${item.width}px`"
                      :background="item.background"
                    >
                      <div v-if="item.tooltip">
                        <div v-if="item.tooltip.title" class="font-weight-bold">{{ item.tooltip.title }}</div>
                      </div>
                      <ul v-if="item.tooltip.lists && item.tooltip.lists.length > 0">
                        <li v-for="curList in item.tooltip.lists" :key="curList">{{ curList }}</li>
                      </ul>
                    </mosaic-horizontal-bar>
                  </div>
                  <div
                    v-else
                    v-tooltip="
                      props.notApplicableMessage
                        ? props.notApplicableMessage.tooltip
                        : 'The filters you’ve selected above the table render the data in this cell not applicable'
                    "
                  >
                    {{ props.notApplicableMessage ? props.notApplicableMessage.text : 'N/A' }}
                  </div>
                </div>
              </div>
              <div
                v-else
                v-tooltip="
                  props.notApplicableMessage
                    ? props.notApplicableMessage.tooltip
                    : 'The filters you’ve selected above the table render the data in this cell not applicable'
                "
              >
                {{ props.notApplicableMessage ? props.notApplicableMessage.text : 'N/A' }}
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Column">
import { computed, onUnmounted, watch } from 'vue';
import { ref } from 'vue';
import type { Column, Row, SelectDataFilter, SelectFilterValue } from './mosaic-table';
import {
  isEnumColumn,
  isTextColumn,
  isNumberColumn,
  isGraphColumn,
  hasSingleChipAndMultipleSelection,
  hasMultipleChipsAndMultipleSelection,
  hasMultipleChipsAndSingleSelection,
  hasSingleChipAndSingleSelection,
} from './mosaic-table';
import { paginateList } from '@/components/library/pagination/pagination';
import MosaicHorizontalBar from './MosaicHorizontalBar.vue';
import { useQueryStore } from '@/stores/query';
import type { ConfigTableExport, ExcelRow } from '@/utils/export/export-to-excel';
import { exportDataToExcel } from '@/utils/export/export-to-excel';
import MosaicRoleSelect from '../library/inputs/MosaicRoleSelect.vue';
import type { RoleWithStudent } from '@/store/map-store';
import { isEqual } from 'lodash';
import { useSlots } from 'vue';

// - This is based off the table in TutorEctsOverviewPage, but hasn't been consolidated because of time/regression risk.
// - This component is missing URL syncing because we don't have a script setup implementation yet.

const props = withDefaults(
  defineProps<{
    tableId: string; // see note above
    columns: T[];
    notApplicableMessage?: { tooltip: string; text: string };
    rows: Row[];
    objectTypePluralised: string;
    noDataSuffix?: string;
    minWidth?: string;
    showNotAssigned?: boolean;
    noOverflow?: boolean;
    fixedHeader?: boolean;
    selectFilters?: SelectDataFilter[];
    graphLabels?: { name: string; color: string; value: string | number }[];
    exportConfig?: { title: string; exportOnlyColumns?: [{ name: string; key: string; index: number }] };
  }>(),
  { minWidth: '150px', showNotAssigned: false }
);

const slots = useSlots();
const dataFiltersInUse = ref<SelectFilterValue>({});
const { queryParams } = useQueryStore();

//executed initially and on click of the reset
const updateDataFilters = (cleared: boolean) => {
  props.selectFilters?.forEach(cv => {
    const formattedKey = props.tableId + cv.key;
    const queryValue = queryParams.value[formattedKey];

    if (queryValue && queryValue.trim() !== '' && !cleared) {
      dataFiltersInUse.value[cv.key] = queryParams.value[formattedKey]?.includes('-1')
        ? []
        : queryValue.split(',').map(val => Number(val));
    } else {
      if (dataFiltersInUse.value[cv.key] !== cv.initial) dataFiltersInUse.value[cv.key] = cv.initial;
    }
  });
};

updateDataFilters(false);

const internalColumns = computed(() => {
  return props.columns.map(c => {
    let filter = c.filter || null;
    if (!filter && isTextColumn(c)) {
      filter = { type: 'text', value: c.text };
    }

    if (filter && !filter.value && isEnumColumn(c)) {
      filter.value = (r: Row) => {
        if (c.chip && c.chip(r)) {
          return c.chip(r).value;
        } else if (c.multipleChips && c.multipleChips(r)) {
          return c.multipleChips(r).map(chipObj => chipObj.value) as string[];
        }
      };
    }
    if (!filter && isNumberColumn(c)) {
      filter = { type: 'range', value: c.value };
    }
    return {
      ...c,
      filter,
      sort: c.sort || (isTextColumn(c) ? (x: Row) => c.text(x) : undefined),
    };
  });
});

const hasFilters = computed(() => internalColumns.value.some(c => c.filter));

const sortBy = ref(props.columns[0].key);
const sortDesc = ref(false);
function sortByColumn(c: { key: string }) {
  if (c.key === sortBy.value) {
    sortDesc.value = !sortDesc.value;
  } else {
    sortBy.value = c.key;
    sortDesc.value = false;
  }
}

// How can this by typed so that the key and value types match?
type FilterValues = {
  key: string;
  value: string | number | (string | number | null)[] | null;
  type: 'text' | 'range' | 'select';
  multiple: boolean | undefined;
  multipleChips: true | null;
  items:
    | {
        value: number | string | null;
        title: string;
      }[]
    | null;
  name: string;
}[];

const filterDefaults = internalColumns.value
  .filter(c => c.filter)
  .map(c => {
    if (!c.filter)
      throw `Impossible error - Array.filter doesn't do type narrowing - could use a type guard, but this is easier`;

    let defaultValue;

    if (c.filter) {
      if (isTextColumn(c)) {
        defaultValue = '';
      } else if (isNumberColumn(c)) {
        defaultValue = [0, null];
      } else if (hasSingleChipAndMultipleSelection(c) || hasMultipleChipsAndMultipleSelection(c)) {
        defaultValue = c.filter.items.map(item => item.value);
      } else if (hasMultipleChipsAndSingleSelection(c)) {
        defaultValue = -1;
      } else {
        defaultValue = null;
      }
    } else {
      defaultValue = null;
    }

    return {
      key: c.key,
      name: c.name,
      value: defaultValue,
      type: c.filter.type,
      items: isEnumColumn(c) ? c.filter.items : null,
      multiple: isEnumColumn(c) && (hasSingleChipAndMultipleSelection(c) || hasMultipleChipsAndMultipleSelection(c)),
      multipleChips: hasMultipleChipsAndSingleSelection(c) || hasMultipleChipsAndMultipleSelection(c) || null,
    };
  });

const filterValues = ref(filterDefaults);

//watches changes to queryParams and updates the values
const watchQueryParams = watch(
  [queryParams],
  (oldQueryParams, newQueryParams) => {
    if (isEqual(newQueryParams, oldQueryParams)) return; //avoid update if equal
    let didValuesChange = false;
    const updatedFilters = filterValues.value.map(f => {
      const queryValue = queryParams.value[props.tableId + f.key] ?? null;
      let value: string | number | (string | number | null)[] | null = f.value;
      if (queryValue || queryValue === '') {
        switch (f.type) {
          case 'text':
            if (queryValue) value = queryValue.trim() !== '' ? queryValue : null;
            break;

          case 'select':
            const { multiple, multipleChips } = f;
            //hasMultipleChipsAndMultipleSelection
            if (multipleChips && multiple) {
              value = !queryValue ? [] : queryValue.split(',').map(val => Number(val));
              //hasSingleChipAndSingleSelection
            } else if (!multipleChips && !multiple) {
              value = queryValue.trim() !== '' ? queryValue : null;
              //hasMultipleChipsAndSingleSelection
            } else if (multipleChips && !multiple && typeof Number(queryValue) === 'number' && queryValue) {
              value = Number(queryValue);
              //hasSingleChipAndMultipleSelection
            } else if (!multipleChips && multiple) {
              value = queryValue === '' ? [] : queryValue.split(',').map(val => val);
            }
            break;

          default:
            value = queryValue.split(',').map(val => {
              return val === 'null' ? null : Number(val);
            }) as number[];

            break;
        }
      }
      //avoid recursion
      if (!isEqual(value, f.value)) {
        didValuesChange = true;
      }

      return {
        key: f.key,
        value,
        type: f.type,
        multiple: f.multiple,
        multipleChips: f.multipleChips,
        items: f.type === 'select' ? f.items : null,
        name: f.name,
      };
    });

    if (didValuesChange) {
      filterValues.value = updatedFilters;
    }
  },
  { deep: true, immediate: true }
);

//watches changes on the filters (both row and data filters) and updates queryParams.value
const watchFilters = watch(
  [filterValues, dataFiltersInUse],
  () => {
    let updatedQueryParams = { ...queryParams.value };
    let shouldUpdate = false; //Update only if different or nonexistent to avoid recursion

    filterValues.value.forEach(({ value, type, key }) => {
      const formattedValue =
        type === 'range' && Array.isArray(value) && !value[1] && value[1] !== 0
          ? `${value[0] ?? 0},null`
          : value?.toString();

      const formattedKey = props.tableId + key;

      if (
        (formattedKey in updatedQueryParams && updatedQueryParams[formattedKey] !== formattedValue) ||
        !(formattedKey in updatedQueryParams)
      ) {
        updatedQueryParams = { ...updatedQueryParams, [formattedKey]: formattedValue };
        shouldUpdate = true;
      }
    });

    Object.entries(dataFiltersInUse.value).forEach(([key, value]) => {
      const formattedKey = props.tableId + key;
      const queryValue = updatedQueryParams[formattedKey];
      const isSelectWithoutValue = queryValue?.includes('-1') && value.length === 0;
      const formattedValue = value.length === 0 ? '-1' : value.toString();

      if (
        ((formattedKey in updatedQueryParams && queryValue !== formattedValue) ||
          !(formattedKey in updatedQueryParams)) &&
        !isSelectWithoutValue
      ) {
        updatedQueryParams = {
          ...updatedQueryParams,
          [formattedKey]: value.length === 0 ? '-1' : value.toString(),
        };
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      queryParams.value = { ...updatedQueryParams };
    }
  },
  { deep: true, immediate: true }
);

function resetFilters() {
  filterValues.value = filterDefaults;
  updateDataFilters(true);
}

onUnmounted(() => {
  watchFilters();
  watchQueryParams();
});

// In the TutorEctOverviewsPage the columns are dynamic, so filterValues is a method argument to get reactivity to work
// This may end up being unnnecessary (or there now may be a better way to acheive this), but have kept for the time being
// Would separate functions for separate filter types be better?
function getFilterValue(key: string, filterValues: FilterValues) {
  const filter = filterValues.find(f => f.key === key);
  if (!filter) {
    throw `Can't find filter for key ${key}`;
  }
  return filter.value;
}

function getMinRangeFilterValue(key: string) {
  const filterValue = getFilterValue(key, filterValues.value);
  return filterValue && Array.isArray(filterValue) ? filterValue[0] : null;
}

function getMaxRangeFilterValue(key: string) {
  const filterValue = getFilterValue(key, filterValues.value);
  return filterValue && Array.isArray(filterValue) ? filterValue[1] : null;
}

function minRangeFilterUpdated(key: string, value: number) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key && Array.isArray(f.value)) {
      return { ...f, value: [value, f.value[1]] };
    }
    return f;
  });
}

function maxRangeFilterUpdated(key: string, value: number) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key && Array.isArray(f.value)) {
      return { ...f, value: [f.value[0], value] };
    }
    return f;
  });
}

function filterUpdated(key: string, value: string) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key) {
      return { ...f, value };
    }
    return f;
  });
}

const areFiltersApplicableForRow = (r: Row, c: Column) => {
  if (!props.selectFilters || props.selectFilters.length === 0) return true;
  return props.selectFilters
    .filter(f => !f.affectedColumns || f.affectedColumns.includes(c.key))
    .every(f => {
      const filterValue = dataFiltersInUse.value[f.key];

      return filterValue.some(item => {
        return r[f.key][item];
      });
    });
};

const filteredAndSortedRows = computed(() => {
  const column = internalColumns.value.find(c => c.key === sortBy.value);
  if (!column) throw `Can't find column for key ${sortBy.value}`;
  const sortSelector = column.sort;
  if (!sortSelector) throw `Can't sort by ${column.key} as sort isn't defined for it`;

  const columnsWithFilters = internalColumns.value
    .filter(c => c.filter)
    .map((c: Column) => ({
      ...c,
      filterValue: getFilterValue(c.key, filterValues.value),
    }));

  return props.rows
    .filter(r => {
      return columnsWithFilters.every(c => {
        if (!c.filter)
          throw `Impossible error - Array.filter doesn't do type narrowing - could use a type guard, but this is easier`;
        if (!c.filter.value) throw 'Filter specified, but it either needs a value or to be used';

        const columnValue = c.filter.value(r, dataFiltersInUse.value);
        const { filterValue } = c;
        const isColumnValueArray = Array.isArray(columnValue);
        const isFilterValueArray = Array.isArray(filterValue);

        if (isTextColumn(c) && typeof filterValue === 'string' && columnValue) {
          return columnValue
            .toString()
            .toLowerCase()
            .includes((filterValue || '').toLowerCase());
        }

        if (isEnumColumn(c)) {
          if (hasSingleChipAndMultipleSelection(c) && isFilterValueArray && !isColumnValueArray) {
            return columnValue && filterValue?.includes(columnValue);
          }

          if (hasSingleChipAndSingleSelection(c)) {
            return filterValue === null || columnValue === filterValue;
          }

          if (hasMultipleChipsAndSingleSelection(c) && isColumnValueArray) {
            return filterValue === -1 || columnValue.some(cv => cv === filterValue);
          }

          if (hasMultipleChipsAndMultipleSelection(c) && isColumnValueArray && isFilterValueArray) {
            const filterValues = Object.values(filterValue);
            return columnValue?.some(v => filterValues.includes(v));
          }
        }

        if (isNumberColumn(c) && isFilterValueArray && typeof columnValue === 'number') {
          return (
            (!filterValue[0] && filterValue[0] !== 0) ||
            (columnValue >= Number(filterValue[0]) &&
              ((!filterValue[1] && filterValue[1] !== 0) || columnValue <= Number(filterValue[1])))
          );
        }

        return true;
      });
    })
    .sortBy(sortSelector, sortDesc.value ? 'desc' : 'asc');
});

const tooltipColumnKeys = ref<string[]>([]);
function onColumnHover(event: MouseEvent, key: string) {
  const target = event.target as HTMLSpanElement;
  if (target.offsetHeight < target.scrollHeight) tooltipColumnKeys.value.push(key);
}

const { paginatedList: paginatedRows, currentPage, pageSize, paginationTotal } = paginateList(filteredAndSortedRows);

function exportToExcel() {
  // get the row filters
  const filters = filterValues.value
    .map(cv => {
      const f = [];
      if (cv.key) {
        const { name, items, type, value } = cv;
        f.push(name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()); //capitalize the filter name
        f.push({ select: 'Dropdown', range: 'Range', text: 'Text' }[type] || ''); //type of filter

        if (!value) {
          f.push('No filtering applied');
        } else {
          //if the filter is of type select
          if (type === 'select' && value) {
            if (Array.isArray(value)) {
              const values = value.map(v => items?.find(item => v === item.value)).map(v => v?.title);

              f.push(
                values.length === 0
                  ? 'All excluded'
                  : values.length == items?.length
                  ? 'No filtering applied'
                  : values.join(', ')
              );
            } else {
              f.push(items?.find(item => value == item.value)?.title);
            }
            //type the filter is range
          } else if (type === 'range' && Array.isArray(value)) {
            f.push(`Min: ${value[0]} | Max: ${value[1]}`);
            //if the filter is of type text
          } else {
            f.push(value);
          }
        }
        return f.flat();
      }
    })
    .filter(Boolean);

  const filterColumns = ['Filter by', 'Filter type', 'Filters applied	'];

  //Add titles to the filter's tables
  if (hasFilters.value) {
    filters.unshift(filterColumns);
    if (props.selectFilters) filters.unshift(['ROW FILTERS (on the table)']);
  }

  if (props.selectFilters && props.selectFilters.length > 0)
    filters.push([], [], ['DATA FILTERS (above the table)'], filterColumns);

  //get the filter's data
  props.selectFilters?.forEach(cv => {
    if (dataFiltersInUse.value && dataFiltersInUse.value[cv.key]) {
      const selected = [] as string[];
      dataFiltersInUse.value[cv.key].forEach(selectedValue => {
        const selectedItem = cv.items.find(item => item.value === selectedValue);
        if (selectedItem) {
          selected.push(selectedItem.name);
        }
      });

      const name = cv.name.replace('Filter by', '').trim();
      filters.push([
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        'Dropdown',
        selected.length === 0
          ? 'All excluded'
          : selected.length === cv.items.length
          ? 'No filtering applied'
          : selected.join(', '),
      ]);
    }
  });

  const areFiltersApplied = hasFilters.value || Object.keys(dataFiltersInUse.value).length !== 0;
  const title = props.exportConfig?.title;

  //the size cannot go over 31 characters
  const cMax = 17;
  const mainTabTitle = `${
    areFiltersApplied && title && title.length > cMax
      ? title.substring(0, cMax) + '... (filtered)'
      : areFiltersApplied && title && title.length < cMax
      ? title + ' (filtered)'
      : 'Table (filtered)'
  }`;

  const exportTableConfig = {
    emptyMessage: `There are no ${props.objectTypePluralised} for these filters`,
    title: props.exportConfig?.title,
    tabTitle: mainTabTitle,
    otherSheets: areFiltersApplied ? [{ tabTitle: 'Filters', data: filters }] : [],
  };

  const data: ExcelRow[] = [];

  //get the table's data
  filteredAndSortedRows.value.forEach(r => {
    const row: ExcelRow = {};
    props.columns.forEach((c, i) => {
      const exportOnlyColumns = props?.exportConfig?.exportOnlyColumns ?? [];
      const matchingColumn = exportOnlyColumns.find(x => x.index === i);
      if (matchingColumn) row[matchingColumn.name] = r[matchingColumn.key];

      const cName = c.name + ' ';
      if (areFiltersApplicableForRow(r, c)) {
        const rawRow = r[c.key];
        if (isTextColumn(c)) {
          row[cName] = rawRow;
        } else if (isNumberColumn(c)) {
          row[cName] = c.friendlyAlternativeValue
            ? c.friendlyAlternativeValue(r, dataFiltersInUse.value)
            : c.value(r, dataFiltersInUse.value);
        } else if (isEnumColumn(c)) {
          if (c.multipleChips)
            row[cName] = c
              .multipleChips(r)
              .map(mc => mc.text)
              .join(', ');
          if (c.chip) row[cName] = c.chip(r).text;
        } else if (isGraphColumn(c)) {
          const label = c
            .items(r, dataFiltersInUse.value)
            .filter(g => g.label !== '')
            .map(g => g.label)
            .join(', ');
          row[cName] =
            label.trim() !== '' ? label : props.notApplicableMessage ? props.notApplicableMessage.text : 'N/A';
        }
      } else {
        row[cName] = props.notApplicableMessage ? props.notApplicableMessage.text : 'N/A';
      }
      if (!row[cName] && props.showNotAssigned) row[cName] = 'Not Assigned';
    });
    data.push(row);
  });

  exportDataToExcel({ ...exportTableConfig, data } as ConfigTableExport);
}
</script>

<style scoped>
.clickable {
  text-decoration: underline;
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
  white-space: nowrap;
}

.table-buttons:only-child {
  margin-left: auto !important;
}

.column-header-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filters-export-container,
.data-filters {
  display: flex;
  align-content: flex-end;
}

.filters-export-container {
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 10px;
}

.data-filters {
  justify-content: end;
  flex-wrap: wrap;
}

.data-filters:empty {
  display: none;
}

.disable-row {
  background-color: rgb(var(--v-theme-secondary-lighten-1));
}

.graph-container {
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
}

.graph-labels {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

.multiple-v-select {
  min-width: 230px !important;
  width: 230px;
}

.not-assigned-text {
  font-size: 0.75rem;
  color: #999;
  padding-top: 4px;
}

.parent {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin-left: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
}

td {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

td.highlight-cell-on-hover:hover {
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  border-radius: 4px;
}
@media (max-width: 600px) {
  .multiple-v-select {
    width: 100%;
  }
  .parent {
    justify-content: flex-end;
  }
}
</style>
