<template>
  <div>
    <mosaic-tab-card-page
      object-type="Targets"
      :object-type-is-plural="true"
      title="Target Monitoring"
      :headers="tabHeaders"
      :load="loadTargets"
      type="table"
      @tab-selected="tab = $event"
    >
      <!--
      I'm unsure from a page design point of view whether these should be in a lot or a component used within both tabs
      as I when I change tab I don't expect something within the tab to not change. I think it also gets a bit odd if we had
      to add a filter to one tab and not the other.
    -->
      <template v-if="tab != 'qa'" #shared-filters>
        <div class="d-flex align-center mb-6">
          <mosaic-cohort-monitoring-filters
            :students="selectedCohortStudents"
            @update:filtered-student-ids="filteredStudentIds = $event"
          />
          <monitoring-placement-select
            v-if="hasPlacements"
            v-model:selected-placement-id="selectedPlacementId"
            class="ml-4"
          />
        </div>
      </template>

      <template #qa-tab-item>
        <q-a-week-switcher @update:week="loadSelectedWeekTargets($event)"></q-a-week-switcher>
        <q-a-target-filters
          class="ml-4"
          @update:filtered-student-ids="qAFilteredStudentIds = $event"
          @update:display="selectedQATargetDisplayOption = $event"
          @update:curriculum-linked-status="selectedCurriculumLinkedStatus = $event"
          @update:action-status="selectedActionStatus = $event"
        />
        <mosaic-card-subheading class="pt-4">Targets set this week</mosaic-card-subheading>
        <div v-if="filteredQATargets.length == 0" class="py-4">No targets set this week.</div>
        <template v-else-if="selectedQATargetDisplayOption == 'list'">
          <q-a-target-panel :targets="filteredQATargets"></q-a-target-panel
        ></template>
        <template v-else>
          <div v-for="(targets, studentName) in QATargetsByStudent" :key="studentName">
            <div class="pt-4 text-h6">{{ `${studentName} (${targets.length})` }}</div>
            <q-a-target-panel :targets="targets" hide-student-name></q-a-target-panel>
          </div>
        </template>
      </template>

      <template #setting-tab-item>
        <mosaic-cohort-weekly-monitoring-filter-table
          v-if="selectedPlacement"
          table-id="tar"
          :placement-id="selectedPlacementId"
          object-type-pluralised="Targets"
          :data-text-transform="settingDataTextTransform"
          :rows="monitoringRows"
          :headers="selectedPlacement.headers"
          :name-click-route="nameClickRoute"
          :extra-columns="extraColumns"
          :export-config="{ title: 'Target Setting Counts' }"
        />

        <div v-else class="py-4">
          This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
        </div>
      </template>
      <template #completion-tab-item>
        <mosaic-cohort-weekly-monitoring-filter-table
          v-if="selectedPlacement"
          :placement-id="selectedPlacementId"
          table-id="tts"
          object-type-pluralised="Targets"
          :rows="monitoringRows"
          :headers="[]"
          :name-click-route="nameClickRoute"
          :extra-columns="completionColumns"
          :export-config="{ title: 'Target Completion Counts' }"
        />
        <div v-else class="py-4">
          This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
        </div>
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';
import type { BaseStudentRow, MonitoringPlacement, WeeklyMonitoringHeader } from '../utils/monitoring';
import { useWeeklyMonitoring } from '../utils/monitoring';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref, computed } from 'vue';
import type { Row, EnumColumn } from '@/components/monitoring/mosaic-table';
import MosaicCohortWeeklyMonitoringFilterTable from '@/components/monitoring/MosaicCohortWeeklyMonitoringFilterTable.vue';
import MonitoringPlacementSelect from '@/components/monitoring/MonitoringPlacementSelect.vue';
import moment from 'moment';
import type { QATarget } from './QATargetPanel.vue';
import type { Week } from './QAWeekSwitcher.vue';
import QATargetPanel from './QATargetPanel.vue';
import QATargetFilters from './QATargetFilters.vue';
import QAWeekSwitcher from './QAWeekSwitcher.vue';
import { useApi } from '@/composables/api';

import { formatDateShort } from '@/utils/date';

const api = useApi();

// Page Setup
const tabHeaders = [
  // {
  //   text: 'Weekly QA',
  //   key: 'qa',
  // },
  {
    text: 'Setting',
    key: 'setting',
  },
  {
    text: 'Completion',
    key: 'completion',
  },
];
setBreadcrumbs([
  {
    text: 'Development Targets',
  },
]);

const tab = ref('setting');

type TargetCount = Record<string, { count: number; completed_count: number }>;
type StudentRow = BaseStudentRow & TargetCount;

// Load Targets

type MonitoringApiResponse = MonitoringPlacement<StudentRow>[];

const { hasPlacements, selectedCohort, selectedCohortStudents } = useCohortStore();
const placements = ref<MonitoringPlacement<StudentRow>[]>([]);

async function loadTargets() {
  const response = await api.get<MonitoringApiResponse>(`/cohorts/${selectedCohort.value.id}/target-monitoring`);
  placements.value = response.data;
}

// Table construction
const selectedPlacementId = ref<number>(-1);
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));
const { selectedPlacement, monitoringRows } = useWeeklyMonitoring<StudentRow>(
  placements,
  selectedPlacementId,
  filteredStudentIds
);

// Data Transforms

type Student = {
  id: number;
  name: string;
};

const settingDataTextTransform = (row: Row, h: WeeklyMonitoringHeader) => {
  const studentRow = row as StudentRow;
  return studentRow[h.key].count;
};

const completionColumns = computed(() => {
  if (!selectedPlacement.value) return [];
  return selectedPlacement.value.headers.map<{
    position: number;
    column: EnumColumn & { topLabel: string; bottomLabel: string };
  }>((h, index) => ({
    position: index + 1,
    column: {
      name: h.label,
      key: h.key,
      topLabel: h.label,
      bottomLabel: `${formatDateShort(h.start)} - ${formatDateShort(h.end)}`,
      sticky: false,
      filter: {
        type: 'select',
        items: [
          { title: 'None Completed', value: 'none' },
          { title: 'Some Completed', value: 'some' },
          { title: 'All Completed', value: 'all' },
          { title: 'No Targets Set', value: 'no-targets' },
        ],
      },
      chip: (row: Row) => {
        const studentRow = row as StudentRow;
        const completionState =
          studentRow[h.key].count === 0
            ? 'no-targets'
            : studentRow[h.key].completed_count === studentRow[h.key].count
            ? 'all'
            : studentRow[h.key].completed_count === 0
            ? 'none'
            : 'some';
        return {
          text: studentRow[h.key].completed_count + '/' + studentRow[h.key].count,
          color:
            completionState === 'all'
              ? 'primary'
              : completionState === 'none'
              ? 'error'
              : completionState === 'some'
              ? 'accent'
              : 'secondary',
          value: completionState,
          hide: false,
        };
      },
    },
  }));
});

function nameClickRoute(row: Row) {
  return {
    name: 'TargetsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}

function getStudentActiveTargetCount(studentId: number) {
  return placements.value.reduce((acc, placement) => {
    const studentRow = placement.student_counts.find(student => student.id === studentId);

    if (studentRow) {
      const placementSum = placement.headers.reduce((placementAcc, header) => {
        return placementAcc + (studentRow[header.key].count - studentRow[header.key].completed_count);
      }, 0);
      return acc + placementSum;
    }
    return acc;
  }, 0);
}

const activeCountColumn = computed(() => ({
  name: 'Total Active Targets',
  key: 'active-count',
  noSlot: true,
  sticky: false,
  clickRoute: (row: Row) => nameClickRoute(row),
  value: (row: Row) => getStudentActiveTargetCount(row.id),
}));

const extraColumns = computed(() => [
  {
    column: activeCountColumn.value,
    position: 1,
  },
]);

// QA Tab

const qATargets = ref<QATarget[]>([]);

const apiDateFormat = (date: string) => {
  return moment(date, 'DD/MM/YY').format('YYYY-MM-DD');
};

const loadSelectedWeekTargets = async (currentWeek: Week) => {
  const response = await api.get<QATarget[]>(
    `/cohorts/${selectedCohort.value.id}/qa-targets?startDate=${apiDateFormat(
      currentWeek.startDate
    )}&endDate=${apiDateFormat(currentWeek.endDate)}`
  );
  qATargets.value = response.data;
};

const qAFilteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));
const filteredQATargets = computed(() => {
  return qATargets.value.filter(target => {
    const studentFilter = qAFilteredStudentIds.value.includes(target.studentId);
    const curriculumLinksFilter =
      selectedCurriculumLinkedStatus.value == 'all' ||
      (target.curriculumStatements.length && selectedCurriculumLinkedStatus.value == 'hasLinks') ||
      (!target.curriculumStatements.length && selectedCurriculumLinkedStatus.value == 'noLinks');
    const actionFilter =
      selectedActionStatus.value == 'all' ||
      (target.targetActions.length && selectedActionStatus.value == 'hasActions') ||
      (!target.targetActions.length && selectedActionStatus.value == 'noActions');

    return studentFilter && curriculumLinksFilter && actionFilter;
  });
});

const QATargetsByStudent = computed(() => {
  const targetsByStudent: Record<string, QATarget[]> = {};
  filteredQATargets.value.forEach(target => {
    if (!targetsByStudent[target.studentDisplayName]) {
      targetsByStudent[target.studentDisplayName] = [];
    }
    targetsByStudent[target.studentDisplayName].push(target);
  });
  return targetsByStudent;
});

const selectedQATargetDisplayOption = ref<string>('list');
const selectedCurriculumLinkedStatus = ref<string>('all');
const selectedActionStatus = ref<string>('all');
</script>

<style>
td {
  vertical-align: top;
  padding-top: 8px !important;
}
</style>
