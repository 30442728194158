<template>
  <mosaic-cohort-students-wrapper :students="rows">
    <mosaic-table
      :table-id="tableId || 'wmf'"
      :key="placementId"
      :columns="monitoringColumnsWithExtras"
      :rows="rows"
      :object-type-pluralised="traineeNounCapitalisedAndPluralised"
      :export-config="exportConfig"
    >
      <template #header-cell="{ c }">
        <div v-if="'noSlot' in c">{{ c.name }}</div>
        <div v-else class="align-center">
          <div>{{ c.topLabel }}</div>
          <div>{{ c.bottomLabel }}</div>
        </div>
      </template>
    </mosaic-table>
  </mosaic-cohort-students-wrapper>
</template>

<script setup lang="ts">
import type { Row, Column, NumberColumn } from '@/components/monitoring/mosaic-table';
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import MosaicTable from './MosaicTable.vue';
import type { WeeklyMonitoringHeader } from '@/pages/tutor/utils/monitoring';
import MosaicCohortStudentsWrapper from './MosaicCohortStudentsWrapper.vue';
import { formatDateShort } from '@/utils/date';

const props = withDefaults(
  defineProps<{
    tableId?: string;
    headers: WeeklyMonitoringHeader[];
    placementId: number;
    rows: Row[];
    extraColumns?: {
      column: Column &
        (
          | {
              topLabel: string;
              bottomLabel: string;
            }
          | { noSlot: boolean }
        );
      position: number;
    }[];
    objectTypePluralised: string;
    nameClickRoute: (row: Row) => RouteLocationNamedRaw;
    exportConfig?: { title: string };
    listPageRouteName?: string;
    dataTextTransform?: (row: Row, header: WeeklyMonitoringHeader) => number;
  }>(),
  {
    dataTextTransform: (row: Row, header: WeeklyMonitoringHeader) => row[header.key],
  }
);

const countColumns = computed(() =>
  props.headers.map<NumberColumn & { topLabel: string; bottomLabel: string }>(h => ({
    name: h.label,
    topLabel: h.label,
    bottomLabel: `${formatDateShort(h.start)} - ${formatDateShort(h.end)}`,
    key: h.key,
    sticky: false,
    value: (row: Row) => props.dataTextTransform(row, h),
    clickRoute: props.listPageRouteName
      ? (row: Row) => ({
          name: props.listPageRouteName,
          params: { studentId: row.id },
          query: { start: h.start, end: h.end },
        })
      : undefined,
  }))
);

const monitoringColumnsWithExtras = computed<
  (Column & ({ topLabel: string; bottomLabel: string } | { noSlot: boolean }))[]
>(() => {
  const columns: (Column & ({ topLabel: string; bottomLabel: string } | { noSlot: boolean }))[] = [
    {
      name: 'Name',
      key: 'name',
      noSlot: true,
      sticky: true,
      clickRoute: (row: Row) => props.nameClickRoute(row),
      text: (row: Row) => row.name,
    },
    ...countColumns.value,
  ];
  if (props.extraColumns) {
    props.extraColumns.forEach(c => {
      columns.splice(c.position, 0, c.column);
    });
  }
  return columns;
});
</script>
