<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <mosaic-loading-card type="list" v-else-if="busy" />
    <div v-else>
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title>
            {{ traineeNounCapitalisedAndPluralised }}

            <template #actions>
              <mosaic-card-action-icon-btn
                icon="mdi-table-arrow-down"
                @click.prevent="downloadCohortData()"
                :tooltip="`Download ${traineeNounCapitalisedAndPluralised}`"
              />

              <div class="d-flex align-center">
                <v-btn ripple @click.prevent="addStudent">
                  <div class="d-flex align-center">
                    <v-icon>mdi-plus</v-icon>
                    <span>{{ traineeNounCapitalised() }}</span>
                  </div>
                </v-btn>
                <mosaic-card-action-icon-btn
                  v-if="!selectedInstitution.config.early_careers"
                  class="ml-2"
                  icon="mdi-table-arrow-up"
                  :to="studentBulkUploadTo"
                  :tooltip="`Upload ${traineeNounCapitalisedAndPluralised} in bulk`"
                />
                <div class="d-flex pl-2">
                  <mosaic-help>
                    Default password for new {{ traineeNounPluralised() }}:
                    <span class="font-weight-bold">{{ userStaff.institution_student_default_password }}</span>
                    <br />
                    ({{ traineeNounCapitalisedAndPluralised }} will be prompted to change their password on first login)
                  </mosaic-help>
                </div>
              </div>
            </template>
          </mosaic-card-title>

          <div class="d-flex align-end flex-wrap" style="row-gap: 16px; column-gap: 16px">
            <mosaic-text-field
              v-model="searchTerm"
              style="width: 250px"
              hide-details
              :label="`Filter by ${selectedInstitution.config.early_careers ? 'name, email or TRN' : 'name or email'}`"
              prepend-icon="mdi-magnify"
            />
            <mosaic-select
              v-if="groups.length > 0"
              v-model="filterGroupId"
              style="width: 150px"
              hide-details
              :items="filterGroups"
              item-title="name"
              item-value="id"
              label="Filter by group"
            ></mosaic-select>
            <mosaic-select
              v-if="usedSubjects.length > 1"
              v-model="usedSubjectsSelectedId"
              style="width: 200px"
              hide-details
              :items="usedSubjects"
              item-title="name"
              item-value="id"
              label="Filter by subject"
            >
              <template #item="{ item, props }">
                <v-divider v-if="item.raw.divider" />
                <v-list-item v-else v-bind="props" :title="item.raw.name" />
              </template>
            </mosaic-select>
            <div>
              <mosaic-checkbox
                v-model="unactivatedOnly"
                no-icon
                density="compact"
                label="Not logged in"
                name="not-logged-in"
              />
            </div>
            <div>
              <mosaic-checkbox
                v-if="selectedInstitution.config.early_careers"
                no-icon
                v-model="inductionCompleteOnly"
                density="compact"
                label="Induction complete"
                name="induction-complete"
              />
            </div>
            <div v-if="isEmailVerificationOnForSelectedInstitution">
              <mosaic-checkbox
                v-model="wontReceiveEmails"
                no-icon
                density="compact"
                label="Won't receive emails"
                name="wont-recieve-emails"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-list v-if="!students.length && !busy && !error">
            <v-list-item ripple @click.prevent="addStudent">
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-account-plus</v-icon>
                </v-avatar>
              </template>

              <v-list-item-title>Add your first {{ traineeNoun() }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <mosaic-list :items="paginatedStudents">
            <template #item="{ item: student }">
              <mosaic-list-item
                :to="studentClickTo(student)"
                :user-avatar="{
                  ...student,
                  id: student.user_id,
                  displayName: student.display_name,
                  hideProfilePicture: student.hide_profile_picture,
                  profilePictureUpdatedAt: student.profile_picture_updated_at,
                }"
                :title="renderStudentTitle(student)"
                :subtitle="renderStudentSubtitle(student)"
              >
                <template #information>
                  <demo-account-badge v-if="student.is_demo" class="pr-2" />
                  <account-locked-chip v-if="student.account_locked" class="pr-2" />
                  <email-status-chip
                    v-if="
                      isEmailVerificationOnForSelectedInstitution && !student.is_demo && student.wont_receive_emails
                    "
                    :email-verified="student.email_verified"
                    :email-bounced="student.email_bounced"
                    :opted-out-of-emails="!!student.opted_out_of_emails"
                  />
                </template>

                <template #actions>
                  <mosaic-icon-btn
                    v-if="student.ect && student.ect.end_of_year_2_review.status === 'approved'"
                    icon="mdi-progress-check"
                    icon-color="primary"
                    tooltip="Mark induction as complete (This ECT has an approved End of Year 2 Assessment)"
                    @click.prevent="markInductionComplete(student.ect)"
                  ></mosaic-icon-btn>

                  <mosaic-icon-btn
                    v-if="canDelete(student)"
                    icon="mdi-delete"
                    :tooltip="'Delete ' + traineeNounCapitalised()"
                    @click.prevent="deleteStudent(student)"
                  />
                  <mosaic-icon-btn
                    v-else
                    icon="mdi-package-down"
                    :tooltip="'Archive ' + traineeNounCapitalised()"
                    @click.prevent="archiveStudent(student)"
                  />
                  <mosaic-icon-btn
                    icon="lock-reset"
                    tooltip="Reset Password"
                    class="mr-2"
                    @click.prevent="resetPassword(student)"
                    :disabled="student.force_microsoft_sso"
                    :disabled-tooltip="`Cannot reset password as this ${traineeNounCapitalised()} must sign in with Microsoft`"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
          <div v-if="students.length > 0 && filteredStudents.length === 0" class="px-4 pb-4">
            You have no {{ traineeNounCapitalisedAndPluralised }} for these filters
          </div>
        </v-card-text>
      </v-card>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredStudents.length" />
    </div>

    <ndt-dialog
      v-model:active="addStudentDialog.active"
      :title="`Add ${traineeNounCapitalised()} to ${selectedCohort?.name}`"
      :error-message="addStudentDialog.error"
    >
      <v-col cols="11">
        <div class="text-h6">{{ traineeNounCapitalised() }} Details</div>
        <v-text-field
          v-model="addStudentDialog.name"
          prepend-icon="mdi-account"
          name="add-student-name"
          label="Name"
          required
          type="text"
        />
        <v-text-field
          v-model="addStudentDialog.email"
          prepend-icon="mdi-at"
          name="add-student-email"
          label="Email"
          required
          type="text"
        />
        <bud-student-fields
          v-model:bud-dob="addStudentDialog.budDOB"
          v-model:bud-reference-number="addStudentDialog.budReferenceNumber"
          @update:valid="budFieldsValid = $event"
        />
        <template v-if="isMosaicEarlyCareers">
          <mosaic-date-picker v-model:date="addStudentDialog.ectStartDate" label="Start date" :exact-width="false" />
          <v-text-field
            v-model="addStudentDialog.termsAlreadyCompleted"
            label="FTE terms of induction completed prior to joining this AB (do not include extension terms)"
            type="number"
            prepend-icon="mdi-clipboard-text-clock"
            :rules="termsCompletedRules"
          ></v-text-field>
          <div class="text-h6">Contract Details</div>
          <v-text-field
            v-model="addStudentDialog.fteRate"
            label="FTE fraction"
            type="number"
            step="0.1"
            :rules="fteRateRules"
            prepend-icon="mdi-timetable"
          ></v-text-field>
          <div>
            <div v-if="loadSchoolsProcessing">Loading Schools...</div>
            <div v-else-if="loadSchoolsError">Loading Schools failed. Please refresh the page.</div>
            <mosaic-autocomplete
              v-else
              v-model="addStudentDialog.schoolId"
              name="edt-school"
              prepend-icon="mdi-domain"
              label="School"
              :items="schools"
              item-value="id"
              item-title="name"
            />
          </div>
          <div class="d-flex align-center">
            <div class="text-h6 pr-2">Claim Details</div>
            <div>
              <mosaic-help>This information is needed to process claims with the TRA</mosaic-help>
            </div>
          </div>
          <mosaic-date-picker
            v-model:date="addStudentDialog.dateOfBirth"
            label="Date of Birth"
            view-mode="year"
            :exact-width="false"
          />
          <div class="d-flex">
            <mosaic-text-field
              v-model="addStudentDialog.trn"
              prepend-icon="mdi-pound"
              name="trn"
              label="TRN"
              :rules="adminTrnRules"
              style="width: 250px"
            />
            <mosaic-checkbox
              v-model="addStudentDialog.trainedOutsideEngland"
              class="pl-2"
              name="trained-outside-england"
              label="Trained outside England?"
              :no-icon="true"
            />
          </div>

          <mosaic-select
            v-model="addStudentDialog.ecfProgramme"
            name="ecf-programme"
            label="ECF programme"
            :items="ecfProgrammeItems"
            prepend-icon="mdi-school-outline"
          />

          <mosaic-text-field
            v-if="addStudentDialog.ecfProgramme && addStudentDialog.ecfProgramme !== 'School-based Induction Programme'"
            v-model="addStudentDialog.ecfProvider"
            name="ecf-provider"
            label="ECF provider"
            prepend-icon="mdi-school-outline"
          />
        </template>
        <template v-else>
          <div class="d-flex" style="max-width: 1000px">
            <v-select
              v-model="addStudentDialog.subjectId"
              prepend-icon="mdi-pencil-ruler"
              label="Main Subject"
              name="edit-student-subject"
              :items="subjectsWithNullOption"
              placeholder="Main Subject"
              item-title="name"
              item-value="id"
            />
            <v-select
              v-model="addStudentDialog.additionalSubjectId"
              prepend-icon="mdi-pencil-ruler"
              class="pl-6"
              label="Additional Subject"
              name="edit-student-additional-subject"
              :items="subjectsWithNullOption"
              placeholder="Additional Subject"
              item-title="name"
              item-value="id"
              :disabled="!addStudentDialog.subjectId"
            />
          </div>
        </template>
        <div class="d-flex align-center">
          <mosaic-checkbox
            v-model="addStudentDialog.isDemo"
            class="pr-2"
            no-icon
            label="Create as demo account"
          /><mosaic-help
            ><div>Demo accounts are intended for testing or demonstrating functionality</div>
            <div>and will not trigger any automated emails from Mosaic.</div></mosaic-help
          >
        </div>
        <force-microsoft-sso-checkbox v-model="addStudentDialog.forceMicrosoftSso" type="student" />
      </v-col>
      <div class="px-4" v-if="!addStudentDialog.forceMicrosoftSso">
        Note: the default password for new {{ traineeNounCapitalisedAndPluralised }} is
        <span class="font-weight-bold">{{ userStaff.institution_student_default_password }}</span>
        <br />
        ({{ traineeNounCapitalisedAndPluralised }} will be prompted to change their password on first login)
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canAddStudent" @click.prevent="submitAddStudent()">Add</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      :title="`Delete ${traineeNounCapitalised()}`"
      :error-message="deleteDialog.error"
      :width="500"
    >
      <div>Are you sure you want to delete "{{ deleteDialog.title }}"?</div>
      <div class="pt-2">This action is not reversible.</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteStudent()"
          >Delete
        </v-btn>
      </template>
    </ndt-dialog>
    <mosaic-dialog
      v-model:active="archiveDialog.active"
      :title="`Archive ${traineeNounCapitalised()}`"
      :error-message="archiveDialog.error"
    >
      <div>Are you sure you want to archive {{ archiveDialog.title }}'s account?</div>
      <div class="pt-2">You will be able to retrieve this account later by contacting support.</div>
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          color="error"
          :disabled="archiveDialog.processing"
          @click.prevent="submitArchiveStudent()"
          >Archive
        </mosaic-btn>
      </template>
    </mosaic-dialog>
    <ndt-dialog
      v-model:active="resetPasswordDialog.active"
      title="Reset Password"
      :on-close="resetPasswordDialogClose"
      :error-message="resetPasswordDialog.errorMessage"
    >
      <div>
        <v-alert v-if="resetPasswordDialog.accountLocked" type="info" variant="outlined"
          >This account is locked due to too many incorrect login attempts. Resetting the password will unlock the
          account.</v-alert
        >
        <mosaic-password-text-field
          v-model="resetPasswordDialog.newPassword"
          name="newPassword"
          label="Password"
          :validate-password="true"
          :revealed="true"
          @password-valid="passwordValid = $event"
        />
        <mosaic-password-text-field
          v-model="resetPasswordDialog.newPasswordConfirmation"
          name="newPasswordConfirmation"
          label="Password Confirmation"
          :rules="passwordConfirmationRules"
          :revealed="true"
          @keyup.enter="submitResetPassword"
        />
        <div class="d-flex justify-end">
          <mosaic-password-security-link />
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitResetPassword" @click.prevent="submitResetPassword()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
    <induction-complete-dialog
      v-if="inductionCompletionDialog.ect"
      v-model:active="inductionCompletionDialog.active"
      :ect="inductionCompletionDialog.ect"
      @submit="submitMarkInductionComplete"
    />
  </div>
</template>

<script>
import NdtDialog from '../../components/NdtDialog.vue';
import DemoAccountBadge from '../../components/DemoAccountBadge.vue';
import { mapGetters, mapState } from 'vuex';
import { validateEmail } from '../../utils/email';
import XLSX from 'xlsx';
import { fteRateRules, termsCompletedRules, validationsPass, adminTrnRules } from '../../utils/validations';
import { ecfProgrammeItems } from '../../utils/ect/ect';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import moment from 'moment';
import { generateStongPassword } from '@/utils/passwords';
import { findStudentLevelHomePage } from '@/utils/navigation';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import InductionCompleteDialog from '@/components/InductionCompleteDialog.vue';
import { mapStateProcessingAndError } from '@/store/map-store';
import { getEmailStatusChipParams } from '../../utils/email-verification';
import MosaicLoadingCard from '@/components/library/loading/MosaicLoadingCard.vue';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';
import { useCohortStore } from '@/stores/cohort';
import BudStudentFields from '@/components/bud/BudStudentFields.vue';

const addStudentDialogDefaults = {
  active: false,
  email: '',
  name: '',
  error: '',
  processing: false,
  number: '',
  subjectId: null,
  additionalSubjectId: null,
  usedSubjects: [],
  ectStartDate: moment().format('YYYY-MM-DD'),
  termsAlreadyCompleted: 0,
  fteRate: 1,
  trainedOutsideEngland: false,
  dateOfBirth: null,
  ecfProgramme: null,
  ecfProvider: '',
  trn: '',
  isDemo: false,
  forceMicrosoftSso: false,
  schoolId: null,
  budReferenceNumber: '',
  budDOB: null,
};

export default {
  name: 'TutorStudentListPage',
  components: {
    NdtDialog,
    DemoAccountBadge,
    EmailStatusChip,
    AccountLockedChip,
    InductionCompleteDialog,
    MosaicLoadingCard,
    ForceMicrosoftSsoCheckbox,
    BudStudentFields,
  },
  mixins: [
    syncQueryParamsMixin({
      searchTerm: { query: 'name' },
      usedSubjectsSelectedId: { query: 'subject', type: 'integer' },
      filterGroupId: { query: 'group', type: 'integer' },
      unactivatedOnly: { query: 'unactivatedOnly', type: 'boolean' },
      wontReceiveEmails: { query: 'wontReceiveEmails', type: 'boolean' },
      inductionCompleteOnly: { query: 'inductionCompleteOnly', type: 'boolean' },
    }),
  ],
  setup() {
    const {
      actions: { clearInstitutionStaff },
    } = useInstitutionStaffStore();

    const queryStore = useQueryStore();
    const {
      selectedCohortStudents,
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { clearInstitutionStaff, queryStore, selectedCohortStudents, loadCohortStudents };
  },
  data: function () {
    return {
      inductionCompletionDialog: {
        active: false,
        ect: null,
      },
      inductionCompleteOnly: false,
      unactivatedOnly: false,
      wontReceiveEmails: false,
      currentPage: 1,
      pageSize: 10,
      addStudentDialog: {
        ...addStudentDialogDefaults,
      },
      deleteDialog: {
        active: false,
        id: null,
        title: '',
        error: '',
        processing: false,
      },
      archiveDialog: {
        active: false,
        id: null,
        title: '',
        error: '',
        processing: false,
      },
      resetPasswordDialog: {
        active: false,
        name: '',
        userId: null,
        accountLocked: false,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      },
      students: [],
      filterGroupId: null,
      busy: true,
      error: '',
      searchTerm: '',
      usedSubjectsSelectedId: null,
      ecfProgrammeItems,
      fteRateRules,
      termsCompletedRules,
      adminTrnRules,
      passwordValid: false,
      passwordConfirmationRules: [],
      budFieldsValid: true,
    };
  },
  computed: {
    ...mapState([
      'user',
      'selectedCohort',
      'subjects',
      'standards',
      'selectedInstitution',
      'userStaff',
      'groups',
      'schools',
    ]),
    ...mapState({ stateStudents: 'students' }),
    ...mapGetters(['substandards', 'isMosaicEarlyCareers', 'isEmailVerificationOnForSelectedInstitution']),
    ...mapStateProcessingAndError(['loadSchools']),
    breadcrumbs() {
      return [
        {
          text: this.traineeNounCapitalisedAndPluralised,
        },
      ];
    },
    studentBulkUploadTo() {
      return {
        name: 'CohortBulkUploadPage',
        params: {
          cohortId: this.selectedCohort.id,
        },
      };
    },
    canAddStudent() {
      return (
        !this.addStudentDialog.processing &&
        this.addStudentDialog.email &&
        this.budFieldsValid &&
        (!this.isMosaicEarlyCareers ||
          (this.addStudentDialog.ectStartDate &&
            validationsPass(this.termsCompletedRules, this.addStudentDialog.termsAlreadyCompleted) &&
            validationsPass(this.fteRateRules, this.addStudentDialog.fteRate) &&
            (validationsPass(this.adminTrnRules, this.addStudentDialog.trn) || !this.addStudentDialog.trn) &&
            this.addStudentDialog.schoolId))
      );
    },
    canSubmitResetPassword() {
      return (
        !this.resetPasswordDialog.processing &&
        !!this.resetPasswordDialog.newPassword &&
        validationsPass(this.passwordConfirmationRules, this.resetPasswordDialog.newPasswordConfirmation) &&
        this.passwordValid
      );
    },
    usedSubjects() {
      const usedSubjects = [];
      this.students.forEach(s => {
        if (s.subject && !usedSubjects.some(su => su.id === s.subject?.id)) usedSubjects.push(s.subject);
        if (s.additional_subject && !usedSubjects.some(su => su.id === s.additional_subject?.id))
          usedSubjects.push(s.additional_subject);
      });
      const subjectList = [
        {
          name: 'All Subjects',
          id: null,
        },
        {
          name: 'No Subject',
          id: 'no-subject',
        },
      ];
      if (usedSubjects.length > 0) {
        subjectList.push({
          divider: true,
        });
      }
      return subjectList.concat(usedSubjects.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)));
    },
    subjectsWithNullOption() {
      return this.subjects.concat({
        name: 'No Subject',
        id: null,
      });
    },
    filteredStudents() {
      return this.students
        .filter(s => {
          const activatedFilter = !this.unactivatedOnly || !s.last_active_at;
          const receiveEmailsFilter = !this.wontReceiveEmails || s.wont_receive_emails;
          const inductionCompleteFilter =
            !this.selectedInstitution.config.early_careers ||
            !this.inductionCompleteOnly ||
            s.ect.end_of_year_2_review.status === 'approved';
          const nameFilter =
            s.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            s.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            (this.selectedInstitution.config.early_careers &&
              s.ect.trn.toLowerCase().includes(this.searchTerm.toLowerCase()));
          const groupsFilter =
            !this.filterGroupId || s.student_groups.map(x => x.group_id).includes(this.filterGroupId);
          const subjectsFilter =
            !this.usedSubjectsSelectedId ||
            (!s.subject && this.usedSubjectsSelectedId === 'no-subject') ||
            s.subject?.id === this.usedSubjectsSelectedId ||
            s.additional_subject?.id === this.usedSubjectsSelectedId;
          return (
            activatedFilter &&
            nameFilter &&
            groupsFilter &&
            subjectsFilter &&
            receiveEmailsFilter &&
            inductionCompleteFilter
          );
        })
        .sortBy('name');
    },
    paginatedStudents() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredStudents.slice(index, index + this.pageSize);
    },
    filterGroups() {
      return [{ id: null, name: 'All Groups' }].concat(this.groups);
    },
    selectedGroupName() {
      return this.filterGroups.find(g => g.id == this.filterGroupId).name;
    },
    selectedSubjectName() {
      return this.usedSubjects.find(s => s.id == this.usedSubjectsSelectedId).name;
    },
    anyStudentscanDelete() {
      return this.filteredStudents.some(s => this.canDelete(s));
    },
  },

  watch: {
    filteredStudents(x, y) {
      const oldIds = y.map(s => s.id);
      if (x.length !== y.length || x.some(s => !oldIds.includes(s.id))) this.currentPage = 1;
    },
    selectedCohortStudents(x) {
      if (x) this.students = this.selectedCohortStudents;
    },
  },

  created() {
    this.passwordConfirmationRules = [
      v => (v && v === this.resetPasswordDialog.newPassword) || 'Password confirmation should match password',
    ];

    if (this.selectedInstitution.config.early_careers) {
      this.$store.dispatch('loadSchools');
    }

    if (this.$route.params.cohortId) {
      this.error = '';
      this.busy = true;
      this.students = this.selectedCohortStudents;
      this.busy = false;

      // This ensures the students are up to date, but doesn't block page load (as they've generally already been loaded)
      this.refreshStudentList();
      this.$store.dispatch('loadSubjects');
    } else {
      this.busy = false;
      this.students = this.stateStudents;
    }
  },

  methods: {
    canDelete(student) {
      return !student.activated || student.is_demo;
    },
    studentClickTo(student) {
      return findStudentLevelHomePage(this.userStaff, student);
    },

    downloadCohortData() {
      const xlxs = XLSX.utils.book_new();
      const filters = [
        ['Search term', 'Group', 'Subject', 'Show logged in users?'],
        [this.searchTerm || 'None', this.selectedGroupName, this.selectedSubjectName, !this.unactivatedOnly],
      ];
      const headers = [
        'Email',
        'Name',
        'Subject',
        'Additional Subject',
        'Last active on',
        'Has linked storage',
        'Storage email',
      ];
      if (this.isEmailVerificationOnForSelectedInstitution) headers.push('Email verification status');
      const data = [
        headers,
        ...this.filteredStudents.map(x => [
          x.email,
          x.name,
          x.subject?.name,
          x.additional_subject?.name,
          x.last_active_at ? this.formatDate(x.last_active_at) : 'Has never logged in',
          x.storage_set_up,
          x.storage_email,
          x.is_demo
            ? 'Demo user'
            : this.isEmailVerificationOnForSelectedInstitution
            ? getEmailStatusChipParams(x.email_verified, x.email_bounced, x.opted_out_of_emails).friendlyValue
            : null,
        ]),
      ];
      XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), this.traineeNounCapitalisedAndPluralised);
      XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(filters), `Applied Filters`);
      XLSX.writeFile(xlxs, `${this.selectedCohort.name} (${moment().format('DD-MM-YYYY')}).xlsx`);
    },
    addStudent() {
      this.addStudentDialog.active = true;
      this.addStudentDialog.forceMicrosoftSso = this.selectedInstitution.config.allow_forcing_microsoft_sso;
    },
    submitAddStudent() {
      this.addStudentDialog.processing = true;
      if (!validateEmail(this.addStudentDialog.email)) {
        this.addStudentDialog.error = 'Please supply a valid email';
        this.addStudentDialog.processing = false;
        return;
      }
      this.$api
        .post(`/students`, {
          email: this.addStudentDialog.email,
          name: this.addStudentDialog.name,
          cohortId: this.selectedCohort.id,
          subject_id: this.addStudentDialog.subjectId,
          additional_subject_id: this.addStudentDialog.additionalSubjectId,
          ect_start_date: this.addStudentDialog.ectStartDate,
          fte_rate: parseFloat(this.addStudentDialog.fteRate),
          terms_already_completed: parseFloat(this.addStudentDialog.termsAlreadyCompleted),
          trn: this.addStudentDialog.trn,
          ecf_provider: this.addStudentDialog.ecfProvider,
          ecf_programme: this.addStudentDialog.ecfProgramme,
          date_of_birth: this.addStudentDialog.dateOfBirth,
          trained_outside_england: this.addStudentDialog.trainedOutsideEngland,
          is_demo: this.addStudentDialog.isDemo,
          force_microsoft_sso: this.addStudentDialog.forceMicrosoftSso,
          school_id: this.addStudentDialog.schoolId,
          bud_reference_number: this.addStudentDialog.budReferenceNumber,
          bud_dob: this.addStudentDialog.budDOB,
        })
        .then(
          async () => {
            await this.refreshStudentList();
            this.addStudentDialog = {
              ...addStudentDialogDefaults,
            };
            this.$store.commit('clearActiveEcts');
          },
          e => {
            if (e.response?.status === 409) {
              this.addStudentDialog.error = `A Mosaic account with that email already exists`;
            } else {
              this.addStudentDialog.error = `Sorry, cannot add ${this.traineeNounCapitalisedAndPluralised} at the moment`;
            }
            this.addStudentDialog.processing = false;
          }
        );
    },
    deleteStudent(student) {
      this.deleteDialog = {
        active: true,
        id: student.id,
        title: student.email,
        processing: false,
      };
    },
    submitDeleteStudent() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/students/${this.deleteDialog.id}`).then(
        () => {
          this.deleteDialog = {
            active: false,
            processing: false,
          };
          this.refreshStudentList();
          this.clearInstitutionStaff();
        },
        () => {
          this.deleteDialog.error = `Sorry, cannot delete this ${this.traineeNounCapitalised()} at the moment`;
          this.deleteDialog.processing = false;
        }
      );
    },
    archiveStudent(student) {
      this.archiveDialog = {
        active: true,
        id: student.id,
        title: student.name || student.email,
        processing: false,
        error: '',
      };
    },
    async submitArchiveStudent() {
      this.archiveDialog.processing = true;
      try {
        await this.$api.put(`students/${this.archiveDialog.id}/archive`);
        this.archiveDialog = {
          active: false,
          processing: false,
        };
        this.refreshStudentList();
        this.clearInstitutionStaff();
      } catch (e) {
        this.archiveDialog.error = `Sorry, cannot archive this ${this.traineeNounCapitalised()} at the moment`;
        this.archiveDialog.processing = false;
      }
    },
    renderStudentTitle: function (student) {
      return student.name ? student.name : student.email;
    },
    renderStudentSubtitle: function (student) {
      const extraDetail =
        ' - ' +
        (this.selectedInstitution.config.early_careers
          ? (student.ect.trn ? `TRN: ${student.ect.trn}` : '') || 'No TRN recorded'
          : student.subject?.name || 'No main subject recorded') +
        (student.additional_subject ? ' with ' + student.additional_subject.name : '') +
        (student.storage_set_up ? '' : ' - Cloud storage not linked') +
        ` - ${
          student.last_active_at ? `Last active on ${this.formatDate(student.last_active_at)}` : 'Has never logged in'
        }`;
      return student.email + extraDetail;
    },
    renderEvidenceCount: function (student) {
      return student.details_filled_in ? student.evidence_count : '';
    },
    renderEvidenceCountText: function (student) {
      return ` piece${student.evidence_count != 1 ? 's' : ''}  of evidence`;
    },
    async refreshStudentList() {
      await this.loadCohortStudents(true, true);
    },
    async resetPassword(student) {
      const password = await generateStongPassword(this.$api, this.user);
      this.resetPasswordDialog = {
        active: true,
        name: student.name,
        id: student.id,
        processing: false,
        newPassword: password,
        newPasswordConfirmation: password,
        accountLocked: student.account_locked,
      };
    },
    async submitResetPassword() {
      this.resetPasswordDialog.processing = true;
      if (this.resetPasswordDialog.newPassword !== this.resetPasswordDialog.newPasswordConfirmation) {
        this.resetPasswordDialog.errorMessage = `Password and confirmation must match`;
        this.resetPasswordDialog.processing = false;
        return;
      }

      this.resetPasswordDialog.errorMessage = '';
      try {
        await this.$api.put(`/students/${this.resetPasswordDialog.id}/reset-password`, {
          password: this.resetPasswordDialog.newPassword,
        });
        await this.refreshStudentList();
        this.resetPasswordDialog.active = false;
      } catch (e) {
        if (e.response?.status === 422 && e.response.data.error_code === 'invalid_password') {
          this.resetPasswordDialog.errorMessage = e.response.data.message;
        } else {
          console.log(e);
          this.resetPasswordDialog.errorMessage = `Sorry, cannot reset this password at the moment`;
        }
      }

      this.resetPasswordDialog.processing = false;
    },
    resetPasswordDialogClose() {
      this.resetPasswordDialog = {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      };
    },
    markInductionComplete(ect) {
      this.inductionCompletionDialog = {
        active: true,
        ect: ect,
      };
    },
    submitMarkInductionComplete() {
      const index = this.students.findIndex(student => student.ect.id === this.inductionCompletionDialog.ect.id);
      this.students.splice(index, 1);
      this.$store.commit('updateSelectedCohortStudents', this.students);
    },
  },
};
</script>
