<template>
  <div>
    <mosaic-dialog-creation-list-page
      object-type="Resource"
      title="Professional Resources"
      :load="load"
      :items="resourceItems"
      icon="mdi-list-box-outline"
      :hide-add="false"
      :can-add-item="canAddResource"
      :add-item="addResource"
      :can-edit-item="canEditResource"
      :edit-item-dialog-opened="editResource"
      :edit-item="saveEditItem"
      :delete-url-stem="deleteUrlStem"
      :move-item="moveResourceListItem"
    >
      <template #add-item-fields="{ onKeyupEnter }">
        <div class="d-flex justify-center align-center mb-4">
          <v-btn-toggle v-model="mode" class="pr-2" color="orange" mandatory variant="outlined" divided>
            <v-btn class="px-6" value="configure">
              Configure
              <v-icon class="pl-2" dark>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="px-6" value="preview">
              Preview
              <v-icon class="pl-2" dark>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="px-6" value="exclusions">
              Exclusions
              <v-icon class="pl-2" dark>mdi-book-open</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="mode === 'configure'">
          <mosaic-text-field
            v-model="addResourceDialog.name"
            name="add-name"
            label="Name (for Mosaic)"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="addResourceDialog.title"
            name="add-title"
            label="Title (for User)"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="addResourceDialog.subtitle"
            name="add-subtitle"
            label="Subtitle"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <div class="font-weight-bold mb-1">Description</div>
          <mosaic-quill
            :read-only="false"
            v-model:contents="addResourceDialog.description"
            name="add-description"
            label="Description"
            class="mb-2"
          />
          <mosaic-text-field
            v-model="addResourceDialog.url"
            name="add-url"
            label="URL (include https://)"
            prepend-icon="mdi-link"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="addResourceDialog.imageFileName"
            name="add-image-file-name"
            label="Image File Name"
            prepend-icon="mdi-image"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="addResourceDialog.imageAltText"
            name="add-image-alt-text"
            label="Image Alt Text"
            prepend-icon="mdi-image"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="addResourceDialog.showTrainees"
            name="add-show-trainees"
            label="Show Trainees"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="addResourceDialog.showEcts"
            name="add-show-ects"
            label="Show ECTs"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="addResourceDialog.showStaff"
            name="add-show-staff"
            label="Show Staff"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-select
            hide-details
            v-model="addResourceDialog.region"
            name="add-region"
            label="Region"
            prepend-icon="mdi-map"
            :items="regions"
            @keyup.enter="onKeyupEnter"
          />
        </div>
        <div v-else-if="mode === 'preview'">
          <professional-resource-card :resource="addResourceDialog"></professional-resource-card>
        </div>
        <div v-else>
          <exclusions-list
            :exclusions="addResourceDialog.institutionExclusions"
            @click-add-exclusion="$event => addExclusion($event, addResourceDialog)"
            @click-remove-exclusion="$event => removeExclusion($event, addResourceDialog)"
          ></exclusions-list>
        </div>
      </template>
      <template #edit-item-fields="{ onKeyupEnter }">
        <div class="d-flex justify-center align-center mb-4">
          <v-btn-toggle v-model="mode" class="pr-2" color="orange" mandatory variant="outlined" divided>
            <v-btn class="px-6" value="configure">
              Configure
              <v-icon class="pl-2" dark>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="px-6" value="preview">
              Preview
              <v-icon class="pl-2" dark>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="px-6" value="exclusions">
              Exclusions
              <v-icon class="pl-2" dark>mdi-book-open</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="mode === 'configure'">
          <mosaic-text-field
            v-model="editResourceDialog.name"
            name="edit-name"
            label="Name"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="editResourceDialog.title"
            name="edit-title"
            label="Title"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="editResourceDialog.subtitle"
            name="edit-subtitle"
            label="Subtitle"
            prepend-icon="mdi-pencil"
            @keyup.enter="onKeyupEnter"
          />
          <div class="font-weight-bold mb-1">Description</div>
          <mosaic-quill
            class="mb-2"
            :read-only="false"
            v-model:contents="editResourceDialog.description"
            name="edit-description"
            label="Description"
          />
          <mosaic-text-field
            v-model="editResourceDialog.url"
            name="edit-url"
            label="URL (include https://)"
            prepend-icon="mdi-link"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="editResourceDialog.imageFileName"
            name="edit-image-file-name"
            label="Image File Name"
            prepend-icon="mdi-image"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-text-field
            v-model="editResourceDialog.imageAltText"
            name="edit-image-alt-text"
            label="Image Alt Text"
            prepend-icon="mdi-image"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="editResourceDialog.showTrainees"
            name="edit-show-trainees"
            label="Show Trainees"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="editResourceDialog.showEcts"
            name="edit-show-ects"
            label="Show ECTs"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-checkbox
            v-model="editResourceDialog.showStaff"
            name="edit-show-staff"
            label="Show Staff"
            prepend-icon="mdi-account"
            @keyup.enter="onKeyupEnter"
          />
          <mosaic-select
            v-model="editResourceDialog.region"
            name="edit-region"
            label="Region"
            prepend-icon="mdi-map"
            :items="regions"
            @keyup.enter="onKeyupEnter"
          />
        </div>
        <div v-else-if="mode === 'preview'">
          <professional-resource-card :resource="editResourceDialog"></professional-resource-card>
        </div>
        <div v-else>
          <exclusions-list
            :exclusions="editResourceDialog.institutionExclusions"
            @click-add-exclusion="$event => addExclusion($event, editResourceDialog)"
            @click-remove-exclusion="$event => removeExclusion($event, editResourceDialog)"
          ></exclusions-list>
        </div>
      </template>
    </mosaic-dialog-creation-list-page>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useMoveListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { computed } from 'vue';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';
import { useEditListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { useApi } from '@/composables/api';
import ProfessionalResourceCard from './ProfessionalResourceCard.vue';
import ExclusionsList from './ExclusionsList.vue';
import type { ProfessionalResource, Exclusion } from '../../stores/professional-resources';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
const api = useApi();

//#region resource list
const resources = ref<ProfessionalResource[]>([]);
const resourceItems = computed(() =>
  resources.value.map(resource => ({
    ...resource,
    title: resource.name,
    subtitle: `Viewers: ${renderViewers(resource)}`,
  }))
);

const renderViewers = (resource: ProfessionalResource) => {
  const viewers = [];
  if (resource.showTrainees) viewers.push('Trainees');
  if (resource.showEcts) viewers.push('ECTs');
  if (resource.showStaff) viewers.push('Staff');
  return viewers.join(', ');
};

const load = async () => {
  const { data } = await api.get<ProfessionalResource[]>(`/professional-resources`);
  resources.value = data;
};

//#endregion

//#region add/edit shared
const mode = ref<'configure' | 'preview'>('configure');
//#endregion

//#region add resource

const regions = [
  {
    text: 'Australian',
    value: 'australian',
  },
  {
    text: 'English',
    value: 'english',
  },
];

const emptyResource = {
  id: -1,
  name: '',
  url: '',
  description: '',
  title: '',
  subtitle: '',
  imageFileName: '',
  imageFileUrl: '',
  imageAltText: '',
  showTrainees: true,
  showEcts: false,
  showStaff: false,
  region: 'english',
  institutionExclusions: [],
} as ProfessionalResource;

const addResourceDialog = ref<ProfessionalResource>({
  ...emptyResource,
});

const canAddResource = computed(() =>
  Boolean(
    addResourceDialog.value.name &&
      addResourceDialog.value.title &&
      addResourceDialog.value.subtitle &&
      addResourceDialog.value.url &&
      addResourceDialog.value.description &&
      addResourceDialog.value.imageFileName &&
      addResourceDialog.value.imageAltText &&
      (addResourceDialog.value.showTrainees || addResourceDialog.value.showEcts || addResourceDialog.value.showStaff) &&
      !!addResourceDialog.value.region
  )
);

const addResource = async () => {
  await api.post<unknown, ProfessionalResource>(`/professional-resources`, addResourceDialog.value);
  addResourceDialog.value = {
    ...emptyResource,
  };
};
//#endregion

//#region edit resource

const editResourceDialog = ref<ProfessionalResource>({
  ...emptyResource,
});

const selectedResource = ref<ProfessionalResource | null>({ ...emptyResource });
function editResource(resourceItem: Item) {
  selectedResource.value = resources.value.find(r => r.id === resourceItem.id) || null;
  if (!selectedResource.value) return;
  editResourceDialog.value = {
    id: selectedResource.value.id,
    name: selectedResource.value.name,
    title: selectedResource.value.title,
    subtitle: selectedResource.value.subtitle,
    url: selectedResource.value.url,
    description: selectedResource.value.description,
    imageFileName: selectedResource.value.imageFileName,
    imageFileUrl: selectedResource.value.imageFileUrl,
    imageAltText: selectedResource.value.imageAltText,
    showTrainees: selectedResource.value.showTrainees,
    showEcts: selectedResource.value.showEcts,
    showStaff: selectedResource.value.showStaff,
    region: selectedResource.value.region,
    institutionExclusions: [...selectedResource.value.institutionExclusions],
  };
}

const exclusionsChanged = computed(() => {
  return (
    editResourceDialog.value.institutionExclusions.length !== selectedResource.value?.institutionExclusions.length ||
    editResourceDialog.value.institutionExclusions.some(
      e => !selectedResource.value?.institutionExclusions.some(oe => oe.institutionId === e.institutionId)
    )
  );
});
const canEditResource = computed(() =>
  Boolean(
    selectedResource.value &&
      editResourceDialog.value.name &&
      editResourceDialog.value.title &&
      editResourceDialog.value.subtitle &&
      editResourceDialog.value.url &&
      editResourceDialog.value.description &&
      editResourceDialog.value.imageFileName &&
      editResourceDialog.value.imageAltText &&
      (editResourceDialog.value.showTrainees ||
        editResourceDialog.value.showEcts ||
        editResourceDialog.value.showStaff) &&
      !!editResourceDialog.value.region &&
      (editResourceDialog.value.name !== selectedResource.value.name ||
        editResourceDialog.value.title !== selectedResource.value.title ||
        editResourceDialog.value.subtitle !== selectedResource.value.subtitle ||
        editResourceDialog.value.url !== selectedResource.value.url ||
        editResourceDialog.value.description !== selectedResource.value.description ||
        editResourceDialog.value.imageFileName !== selectedResource.value.imageFileName ||
        editResourceDialog.value.imageAltText !== selectedResource.value.imageAltText ||
        editResourceDialog.value.showTrainees !== selectedResource.value.showTrainees ||
        editResourceDialog.value.showEcts !== selectedResource.value.showEcts ||
        editResourceDialog.value.showStaff !== selectedResource.value.showStaff ||
        editResourceDialog.value.region !== selectedResource.value.region ||
        exclusionsChanged.value ||
        editResourceDialog.value.subtitle !== selectedResource.value.subtitle)
  )
);

const body = computed(() =>
  Object.fromEntries(Object.entries(editResourceDialog.value).filter(([key]) => !key.startsWith('original')))
);

const { editListItem: saveEditItem } = useEditListItem('/professional-resources', body, api);
const { moveListItem: moveResourceListItem } = useMoveListItem('/professional-resources', api);
//#endregion

//#region delete resource
const deleteUrlStem = '/professional-resources';
//#endregion

//#region exclusions
const addExclusion = async (exclusion: Exclusion | null, dialog: ProfessionalResource) => {
  if (!exclusion) return;
  dialog.institutionExclusions.push(exclusion);
};

const removeExclusion = async (institutionId: number, dialog: ProfessionalResource) => {
  dialog.institutionExclusions = dialog.institutionExclusions.filter(e => e.institutionId !== institutionId);
};
//#endregion
</script>
